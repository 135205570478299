import { useState, useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { Container, Typography, Grid } from '@material-ui/core';
// import TextsmsIcon from '@material-ui/icons/Textsms';

import AxiosInstance from '../utils/axiosInstance';
import Cookies from 'universal-cookie';
import NumberFormat from 'react-number-format';
import { Link, useParams } from 'react-router-dom';

import { ActionButton } from './DynamicButton';
import Skeleton from '../skeleton/Skeleton';
import { Modal, ModalBody, ModalDataList } from './container/Modal';
import { ModalHeading, PageHeading } from './heading/Heading';
import { CategoryProduct, ProductGridList } from './container/ProductContainer';
import { AddressBox, Card, DataBox } from './container/Card';
import { InvoiceContent, InvoiceImageContainer, InvoiceTab, InvoiceData, ButtonGroupInvoice } from './container/InvoiceContainer';
import { TrackingBox } from './container/CartContainer';
import { Notification } from './notification/Notification';
import { ButtonForm, CancelButton, GroupButtonForm } from './form/ButtonForm';

import { AddressState, InvoiceState, ProductState, RouteState, TrackingState } from '../state/state';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            minHeight: '50vh',
            paddingBottom: '30px'
        },
        gridContainer: {
            marginTop: '1.5rem',
        }
    }),
);

const ProductInformation = (invoice: any) => {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [product, setProduct] = useState<ProductState[]>([]);
    const [address, setAddress] = useState<AddressState[]>([]);
    const [tracking, setTracking] = useState<TrackingState[]>([]);

    useEffect(() => {
        const getProduct = async () => {
            await AxiosInstance.get('/sales_detail/' + invoice.id).then(
                res => {
                    if (res.status === 200 && res.data.count >= 0) {
                        setProduct(res.data.result);
                    }
                    else {
                        setProduct([]);
                    }
                }
            )
            setLoading(true);
        }

        const getAddress = async () => {
            let id = invoice.detail.partner_id.length > 0 ? String(invoice.detail.partner_id[0]) : String(invoice.detail.partner_id);
            await AxiosInstance.get('/customers/address?id=' + id).then(
                res => {
                    if (res.status === 200 && res.data.count > 0) {
                        setAddress(res.data.result);
                    }
                    else {
                        console.log(res.data);
                    }
                }
            )
        }

        if (invoice.id !== 0 && open === true) {
            getProduct();
            getAddress();
        }

        return () => {
            setProduct([]);
            setLoading(false);
        }

    }, [invoice, open])

    useEffect(() => {
        const getTracking = async () => {
            await AxiosInstance.get('/sales/order_tracking/' + invoice.id).then(
                res => {
                    if (res.status === 200 && res.data.count >= 0) {
                        setTracking(res.data.result.result);
                    }
                    else {
                        setTracking([]);
                    }
                }
            )
            setLoading(true);
        }

        if (invoice.id !== 0 && open === true && (invoice.status === "shipped" || invoice.status === "completed")) {
            getTracking();
        }

        return () => {
            setTracking([]);
        }

    }, [invoice, open])

    return (
        <>
            <ActionButton label="DETAIL" type="secondary-full" onClick={() => setOpen(!open)} />
            <Modal open={open} onClose={() => setOpen(!open)}>
                <ModalHeading text="Invoice Detail" />
                <Grid container spacing={1}>
                    <Grid item xs={12} md={4}>
                        <ModalDataList>
                            <h5>Invoice Number</h5>
                            <p>{invoice.detail.display_name}</p>
                        </ModalDataList>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <ModalDataList>
                            <h5>Order Date</h5>
                            <p>{invoice.detail.date_order}</p>
                        </ModalDataList>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <ModalDataList>
                            <h5>Status</h5>
                            <p style={{ textTransform: 'capitalize' }}>{invoice.detail.invoice_status}</p>
                        </ModalDataList>
                    </Grid>
                </Grid>
                <DataBox pd="0.5rem 0">
                    <ModalDataList>
                        <h5>Product Detail</h5>
                    </ModalDataList>
                    <Grid container spacing={1}>
                        {!loading && [1, 2].map((n) => (<Grid item xs={12} key={n}><Skeleton type="productList" /></Grid>))}
                        {loading && product.length > 0 && product.map((items, index) => (
                            <Grid item xs={12} key={index}>
                                <ProductGridList
                                    display_name={items.display_name}
                                    qty={items.product_uom_qty}
                                    currency={items.currency_id[1]}
                                    price={items.price_unit}
                                    subtotal={items.price_subtotal}
                                    image={items.image_128}
                                    url={`/product/${items.categ_id[1].split(" / ")[1] !== undefined ? items.categ_id[1].split(" / ")[1].toLowerCase() : items.categ_id[1].toLowerCase()}/${items.id}`}
                                >
                                </ProductGridList>
                            </Grid>
                        ))}
                    </Grid>
                </DataBox>
                <DataBox pd="0.5rem 0">
                    <ModalDataList>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <h5>Subtotal</h5>
                            <p>{String(invoice.detail.currency_id[1])} <NumberFormat value={invoice.detail.amount_untaxed} thousandSeparator="." decimalSeparator="," displayType={'text'} /></p>
                        </div>
                    </ModalDataList>
                    <ModalDataList>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <h5>Tax</h5>
                            <p>{String(invoice.detail.currency_id[1])} <NumberFormat value={invoice.detail.amount_tax} thousandSeparator="." decimalSeparator="," displayType={'text'} /></p>
                        </div>
                    </ModalDataList>
                    <ModalDataList>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <h5>Total Price</h5>
                            <p>{String(invoice.detail.currency_id[1])} <NumberFormat value={invoice.detail.amount_total} thousandSeparator="." decimalSeparator="," displayType={'text'} /></p>
                        </div>
                    </ModalDataList>
                </DataBox>
                <DataBox pd="0.5rem 0">
                    <ModalDataList>
                        <h5>Shipping Address Detail</h5>
                    </ModalDataList>
                    <Grid container spacing={1}>
                        {address.length > 0 && address.map((addressItem) => (
                            <Grid key={addressItem.id} item xs={12}>
                                <AddressBox onClick={(e: any) => e.preventDefault} style={{}}>
                                    <Typography variant="body1" style={{ fontWeight: 'bold', textTransform: 'capitalize' }}>
                                        {addressItem.type} Address
                                    </Typography>
                                    <Typography variant="subtitle1">
                                        {addressItem.name}, {addressItem.phone} - {addressItem.mobile}
                                    </Typography>
                                    <Typography variant="subtitle1">
                                        {addressItem.street}, {addressItem.street2}, {addressItem.urban_village_id[1]}, {addressItem.district_id[1]}, {addressItem.city_id[1]}, {addressItem.state_id[1]}, {addressItem.zip}
                                    </Typography>
                                </AddressBox>
                            </Grid>
                        ))}
                    </Grid>
                </DataBox>
                {(invoice.status === "shipped" || invoice.status === "completed") &&
                    <DataBox pd="0.5rem 0">
                        <ModalDataList>
                            <h5>Tracking Detail</h5>
                        </ModalDataList>
                        <Grid container spacing={1}>
                            {tracking.length > 0 && tracking.map((item, index) => (
                                <Grid key={index} item xs={12}>
                                    <TrackingBox last={(tracking.length - 1) === index}>
                                        <Typography variant="body1" style={{ fontWeight: 'bold', textTransform: 'capitalize' }}>
                                            {item.status}
                                        </Typography>
                                        <Typography variant="subtitle1">
                                            {item.date}
                                        </Typography>
                                    </TrackingBox>
                                </Grid>
                            ))}
                        </Grid>
                    </DataBox>
                }
            </Modal>
        </>
    )
}

const InvoicePhoto = (invoice: any) => {
    const [product, setProduct] = useState<ProductState[]>([]);
    const [size, setSize] = useState({ width: 0, height: 0 })
    var img = new Image();

    useEffect(() => {
        const getProduct = async () => {
            let res = await AxiosInstance.get('/sales_detail/' + invoice.id);
            if (res.status === 200 && res.data.count >= 0) {
                setProduct(res.data.result);
            }
            else {
                setProduct([]);
            }

        }

        if (invoice.id !== 0) {
            getProduct();
        }

        return () => {
            setProduct([]);
        }

    }, [invoice.id])

    useEffect(() => {
        if (product.length > 0) {
            img.src = product[0].image_256;
        }

        img.onload = function () {
            setSize({ width: img.width, height: img.height });
        }
        return () => { setSize({ width: 0, height: 0 }) }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [product])

    return (
        <>
            {product.length > 0 && <img width={size.width} height={size.height} src={product[0].image_256} alt="" ></img>}
        </>
    )
}

const CompleteOrder = (invoice: any) => {
    const [modalComplete, setModalComplete] = useState(false);
    const [updateInvoice, setUpdateInvoice] = useState({ sale_id: invoice.id, picking_no: invoice.picking, state: "done" });
    const [notif, setNotif] = useState({ status: false, message: "" });

    const handleOpen = () => {
        setUpdateInvoice({ sale_id: invoice.id, picking_no: invoice.picking, state: "done" });
        setModalComplete(true);
    }

    const CompleteOrder = async () => {
        await AxiosInstance.post('/sales/update_status_stockpicking', updateInvoice).then(
            res => {
                if (res.status === 200) {
                    setNotif({ status: true, message: res.data.Message });
                    setTimeout(
                        () => { setNotif({ status: false, message: "" }); invoice.childChanged(); },
                        2000
                    );
                }
                else {
                    setNotif({ status: true, message: res.data.Message });
                    setTimeout(
                        () => { setNotif({ status: false, message: "" }); invoice.childChanged(); },
                        2000
                    );
                }
            }
        )
    }

    return (
        <>
            {invoice.status === "shipped" && <ActionButton label="COMPLETE ORDER" type="cart-full" onClick={handleOpen} />}
            <Modal open={modalComplete} onClose={() => setModalComplete(!modalComplete)}>
                <ModalHeading text="Complete Order" />
                <ModalBody>
                    <Typography variant="subtitle1" align={"center"} gutterBottom>
                        Are you sure you want to complete this order <b>{invoice.name}</b>? you can not cancel it after complete this order.
                    </Typography>
                    {notif.status && <Notification type="info" text={notif.message} />}
                </ModalBody>
                <GroupButtonForm>
                    <CancelButton onClick={() => setModalComplete(false)} label="Cancel"></CancelButton>
                    <ButtonForm label="Complete Order" onClick={() => CompleteOrder()}></ButtonForm>
                </GroupButtonForm>
            </Modal>
        </>
    )
}

const Purchase = () => {
    const classes = useStyles();
    const cookies = new Cookies();
    const { id } = useParams<RouteState>();

    const [token] = useState(cookies.get('token'));
    const [status, setStatus] = useState(id);
    const [loading, setLoading] = useState(false);
    const [changed, setChanged] = useState(false);
    const [invoice, setInvoice] = useState<InvoiceState[]>([]);

    const childChanged = () => {
        setChanged(!changed);
    }

    useEffect(() => {
        const getInvoice = async () => {
            await AxiosInstance.get('/sales?find=' + status).then(
                res => {
                    if (res.status === 200 && res.data.count !== 0) {
                        setInvoice(res.data.result);
                    }
                    else {
                        setInvoice([]);
                    }
                }
            )
            setLoading(true);
        }

        if (token) {
            getInvoice();
        }
        else {
            setInvoice([]);
        }

        return () => {
            setInvoice([]);
            setLoading(false);
        }

    }, [token, status, changed])

    return (
        <Container className={classes.root}>
            <PageHeading text="Purchase History" />
            {token &&
                <>
                    <CategoryProduct>
                        <Link to='/purchase/unpaid' onClick={() => setStatus('unpaid')} className={status === 'unpaid' ? 'active' : ''}>
                            Not Paid
                        </Link>
                        <Link to='/purchase/processed' onClick={() => setStatus('processed')} className={status === 'processed' ? 'active' : ''}>
                            Processed
                        </Link>
                        <Link to='/purchase/shipped' onClick={() => setStatus('shipped')} className={status === 'shipped' ? 'active' : ''}>
                            Shipped
                        </Link>
                        <Link to='/purchase/completed' onClick={() => setStatus('completed')} className={status === 'completed' ? 'active' : ''}>
                            Completed
                        </Link>
                    </CategoryProduct>
                    <div className={classes.gridContainer}></div>
                </>
            }
            <Grid container spacing={3}>
                {!token ? <Grid item xs={12}><Typography variant="h6">You must login to see purchase history, <Link to="/login">login here</Link></Typography></Grid> :
                    invoice.length > 0 && loading && invoice.map((items, index) => (
                        <Grid item xs={12} md={12} key={index}>
                            <Card>
                                <InvoiceTab date={items.date_order} invoiceID={items.display_name}></InvoiceTab>
                                <InvoiceContent>
                                    <InvoiceImageContainer>
                                        <InvoicePhoto id={items.id} />
                                    </InvoiceImageContainer>
                                    <InvoiceData>
                                        <div className="invoiceData">
                                            <div className="invoiceStatus">
                                                <label>Status</label>
                                                <h6>{items.invoice_status}</h6>
                                            </div>
                                            <div className="invoicePrice">
                                                <label>Total Price</label>
                                                <h6>{items.currency_id[1]} <NumberFormat value={items.amount_total} thousandSeparator="." decimalSeparator="," displayType={'text'} /></h6>
                                            </div>
                                        </div>
                                        <ButtonGroupInvoice>
                                            {/* <IconButton><TextsmsIcon /><span>CHAT</span></IconButton> */}
                                            <ProductInformation id={items.id} detail={items} status={status} />
                                            {items.xendit_invoice_url && <ActionButton label={status === "unpaid" ? "PAY INVOICE" : "VIEW INVOICE"} type="cart-full" onClick={() => window.open(items.xendit_invoice_url)} />}
                                            {status === "shipped" && <CompleteOrder childChanged={childChanged} id={items.id} name={items.display_name} picking={items.picking_display_name} status={status} />}
                                        </ButtonGroupInvoice>
                                    </InvoiceData>
                                </InvoiceContent>
                            </Card>
                        </Grid>
                    ))}
                {token && invoice.length === 0 && loading && <Grid item xs={12}><Typography variant="subtitle1">There is no purchase history found in {status} invoice</Typography></Grid>}
                {token && !loading && [1, 2].map((items, index) => (
                    <Skeleton key={index} type="invoice"></Skeleton>
                ))}
            </Grid>
        </Container>
    );
}

export default Purchase;