import { useState, useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Container, Typography, Hidden } from '@material-ui/core';

import { useHistory, Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import axios from 'axios';

import styled from 'styled-components';
import { generateMedia } from "styled-media-query";
import 'swiper/swiper.min.css'

import Skeleton from '../../skeleton/Skeleton';
import { PageHeading } from '../heading/Heading';

import { BannerState } from '../../state/state';
import { ActButton } from '../DynamicButton';

const customMedia = generateMedia({
    desktop: "960px",
    mobile: "600px",
});

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingBottom: '0px',
        },
    }),
);

function getWindowDimensions() {
    const { innerWidth: width } = window;
    let device: String = '';
    let left: number;
    let bannerWidth: number;
    if (width < 600) {
        device = 'sm';
        left = 16;
        bannerWidth = width - 2 * left;
    }
    else if (width < 960) {
        device = 'md';
        left = 24;
        bannerWidth = 500;
    }
    else if (width < 1280) {
        device = 'lg';
        left = 24;
        bannerWidth = 750;
    }
    else {
        device = 'xl';
        left = (width - 1200) / 2 - 24;
        bannerWidth = 1200;
    }
    return { width, device, left, bannerWidth };
}

const BannerItem = (props: { items: BannerState }) => {
    const history = useHistory();
    const [size, setSize] = useState({ width: 0, height: 0 })
    var img = new Image();
    img.src = props.items.image_url;

    const toPromo = (url: any) => {
        history.push(url);
    };

    useEffect(() => {
        img.onload = function () {
            setSize({ width: img.width, height: img.height });
        }
        return () => { setSize({ width: 0, height: 0 }) }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div onClick={() => toPromo(props.items.url)} className="bannerInner">
            <div className="bannerContent">
                <span className="bannerPromoName" style={{ color: props.items.top_text_color }}>{props.items.top_text}</span>
                <span className="bannerPromoDesc" style={{ color: props.items.middle_text_color }}>{props.items.middle_text}</span>
                <span className="bannerPromoSlogan" style={{ color: props.items.bottom_text_color }}>{props.items.bottom_text}</span>
            </div>
            <div className="bannerImage">
                <img width={size.width} height={size.height} src={props.items.image_url} alt="banner" />
            </div>
        </div>
    )
}

const SwiperBanner = () => {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const { left, bannerWidth } = windowDimensions;

    const [banner, setBanner] = useState<BannerState[]>([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [windowDimensions]);

    useEffect(() => {
        const getBanner = async () => {
            try {
                await axios.get(process.env.REACT_APP_MINUMMINUM_PUBLIC_API + '/banners').then(res => { setBanner(res.data.result) });
            }
            catch (error) {
                setBanner([]);
                console.log(error)
            }
            setLoading(true);
        }

        getBanner();

        return () => {
            setBanner([]);
            setLoading(false);
        }
    }, []);

    return (
        <BannerBox>
            {!loading && <div style={{ paddingLeft: left, width: bannerWidth, height: '335px' }}><Skeleton type="banner" /></div>}
            {loading && banner.length > 0 && <Swiper
                slidesPerView={'auto'}
                loop={true}
                initialSlide={1}
                // onSlideChange={swiper => console.log(swiper.activeIndex)}
                // onSwiper={swiper => console.log(swiper)}
                className="bannerContainer"
                style={{ paddingLeft: left }}
                // centeredSlides={true}
                grabCursor={true}
            >
                {banner.map((items, index) => (
                    <SwiperSlide key={index} className="bannerWrapper" style={{ width: bannerWidth, background: `radial-gradient(circle, ${items.background_color1} 30%, ${items.background_color2} 100%)` }}>
                        <BannerItem items={items} />
                    </SwiperSlide>
                ))}
            </Swiper>
            }
        </BannerBox>
    )
}

const Banner = () => {
    const classes = useStyles();;
    return (
        <>
            <Container className={classes.root}>
                <PageHeading text="Special Offers" />
            </Container>
            <SwiperBanner></SwiperBanner>
            <Hidden smDown>
                <Container>
                    <OrderNowBox>
                        <picture className="imageOrder">
                            <source srcSet="/assets/images/order-now-photos.webp" type="image/webp" />
                            <source srcSet="/assets/images/order-now-photos.jpg" type="image/jpeg" />
                            <img width="400" height="246" src="/assets/images/order-now-photos.jpg" alt="Order Now" />
                        </picture>
                        <div>
                            <Typography variant="h4" className="title">
                                Cheers... and let <br /> MINUMMINUM.COM refill it everytime
                            </Typography>
                            <Link to="/collection/" className="link">
                                <ActButton style={{ padding: '14px 30px', fontSize: '1.25rem' }}>Let's Order Now</ActButton>
                            </Link>
                        </div>
                    </OrderNowBox>
                </Container>
            </Hidden>
        </>
    );
}

const OrderNowBox = styled.div`
    display: flex;
    align-items: center;

    .imageOrder {
        margin-right: 2rem;

        img, source {
            max-width: 400px;
        }
    }

    .title { font-weight: bold; margin-bottom: 1rem; }

    .link { text-decoration: none }
`

const BannerBox = styled.div`
    .bannerContainer {
        padding-bottom: 50px;

        .swiper-slide-active {
            transform: scale(1)!important;
            box-shadow: 0 7.1px 4.5px -50px rgba(0, 0, 0, 0.255), 0 57px 36px -50px rgba(0, 0, 0, 0.46);
        }

    }
        
    .bannerWrapper {
        border-radius: 30px;
        transform: scale(0.93)!important;
        background: #ddd;
        box-shadow: 0 7.1px 4.5px -50px rgba(0, 0, 0, 0.155), 0 57px 36px -50px rgba(0, 0, 0, 0.31);
        transition: all 0.2s ease-out;

        :hover {
            cursor: pointer;
        }

        :active {
            cursor: grabbing;
        }

        .bannerInner {
            padding: 40px 60px;
            display: flex;
            justify-content: space-between;
            gap: 15px;

            .bannerContent {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                color: #111;
            }

            .bannerImage {
                width: 200px;
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    object-fit: contain;
                    height: 200px;
                }
            }

            .bannerPromoName {
                font-size: 1.5rem;
            }

            .bannerPromoDesc {
                font-size: 3rem;
                font-weight: bold;
            }

            .bannerPromoSlogan {
                font-size: 1.5rem;
            }

        }

        ${customMedia.lessThan("desktop")`
            border-radius: 20px;

            .bannerInner {
                padding: 25px 20px;

                .bannerImage {
                    width: 140px;

                    img {
                        object-fit: contain;
                        height: 140px;
                    }
                }

                .bannerPromoName {
                    font-size: 1rem;
                    line-height: 1;
                }

                .bannerPromoDesc {
                    font-size: 1.75rem;
                    font-weight: bold;
                    line-height: 1;
                }

                .bannerPromoSlogan {
                    font-size: 1rem;
                    line-height: 1;
                }
            }

        `}

        ${customMedia.lessThan("mobile")`
            border-radius: 15px;

            .bannerInner {
                padding: 20px 15px;

                .bannerImage {
                    width: 100px;

                    img {
                        height: 100px;
                    }
                }

                .bannerPromoName {
                    font-size: 0.875rem;
                }

                .bannerPromoDesc {
                    font-size: 1.25rem;
                    font-weight: bold;
                }

                .bannerPromoSlogan {
                    font-size: 0.875rem;
                }
            }

        `}
    }
`

export default Banner;