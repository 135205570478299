import styled from 'styled-components';

const TextInput = (props:any) => {
    return(
        <InputGroup>
            <Label>{props.label}</Label>
            <Input style={{ outline: props.status === 'warning' ? '1px solid #c51212' : props.status === 'success' ? '1px solid #00973f' : '' }} required={props.required} name={props.name} onChange={props.onInputChange} value={props.value} type={props.type} placeholder={props.placeholder}/>
        </InputGroup>
    )
}

const InputGroup = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0.25rem 0;
`

const Label = styled.label`
    margin-bottom: 0.75rem;
`

const Input = styled.input`
    background: #000;
    border: none;
    border-radius: 8px;
    box-shadow: none;
    padding: 15px 20px;
    color: #888;
    font-family: 'Arial', Courier, monospace;
    font-size: 1rem;
    &:focus {
        outline: none;
    }

`

export default TextInput;
