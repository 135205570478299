import { FC } from 'react';
import styled from 'styled-components';
import media from "styled-media-query";
import Typography from '@material-ui/core/Typography';

export const AuthContainer: FC<{}> = ({ children }) => {
    return (
        <Container>{children}</Container>
    )
}

export const HeadingImage = (props: { text: string }) => {
    return (
       <ImageContainer>
            <img className="image" alt="logo" src="/assets/images/logo.png" />
            <Typography variant="subtitle1">
                {props.text}
            </Typography>
       </ImageContainer>
    )
}

export const FormContainer: FC<{}> = ({ children }) => {
    return (
        <Box>{children}</Box>
    )
}

export const SmallFormContainer: FC<{}> = ({ children }) => {
    return (
        <BoxSmall>{children}</BoxSmall>
    )
}

export const ButtonContainer: FC<{}> = ({ children }) => {
    return (
        <SubmitButton>{children}</SubmitButton>
    )
}

const Container = styled.div`
    background: #212121;
    color: #F7F7F7;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const ImageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 4rem;

    & .image {
        width: 250px;
        margin-bottom: 1rem;
    }
`

const Box = styled.div`
    margin: 2rem 0rem;
    width: 50vw;
    ${media.lessThan("large")`
        width: 70vw;
    `}
    ${media.lessThan("medium")`
        width: calc(100vw - 70px);
    `}
`

const BoxSmall = styled.div`
    margin: 2rem 0rem;
    width: 600px;
    ${media.lessThan("large")`
        width: 70vw;
    `}
    ${media.lessThan("medium")`
        width: calc(100vw - 70px);
    `}
`

const SubmitButton = styled.div`
    margin-top: 0.25rem;
`