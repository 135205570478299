import { useState, useEffect, useContext } from "react";
import {
  createStyles,
  withStyles,
  makeStyles,
  Theme,
} from "@material-ui/core/styles";
import {
  Container,
  IconButton,
  Badge,
  InputBase,
  Hidden,
} from "@material-ui/core";

import SearchIcon from "@material-ui/icons/Search";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
// import MailOutlineIcon from '@material-ui/icons/MailOutline';
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import NotificationsNoneOutlinedIcon from "@material-ui/icons/NotificationsNoneOutlined";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import { Link, useHistory, useLocation } from "react-router-dom";
import Cookies from "universal-cookie";
import axios from "axios";
import styled from "styled-components";
import media from "styled-media-query";

import { BadgeContext } from "../../state/context";
import AxiosInstance from "../../utils/axiosInstance";

const StyledBadge = withStyles((theme: Theme) =>
  createStyles({
    badge: {
      top: 5,
      right: 3,
      backgroundColor: "#F4C749",
      color: "#000",
      fontWeight: "bold",
      lineHeight: "0",
    },
  })
)(Badge);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    inputRoot: {
      color: "#333",
      width: "100%",
      zIndex: 1,
    },
    inputInput: {
      padding: theme.spacing(1),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1.5em + ${theme.spacing(3)}px)`,
    },
  })
);

interface CategoryProps {
  id: number;
  display_name: string;
  child_id: any;
}

const ProfileBox = (props: {
  fullName: string;
  loggedIn: boolean;
  logOut: any;
}) => {
  return (
    <>
      <h6 className="name">
        <b>Hello,</b> {props.fullName !== "" ? props.fullName + "!" : "Guest!"}
      </h6>
      <h6 className="item">Find your favorite drinks</h6>
      <h6 className="item">
        {!props.loggedIn ? (
          <Link className="link" to="/login">
            Sign in or register
          </Link>
        ) : (
          <span className="link" onClick={props.logOut}>
            Log out
          </span>
        )}
      </h6>
    </>
  );
};

const Navigation = () => {
  const classes = useStyles();
  const history = useHistory();
  const cookies = new Cookies();
  const location = useLocation();

  const [loggedIn] = useState(cookies.get("isLoggedIn"));
  const [fullName, setFullName] = useState("");
  const [category, setCategory] = useState<CategoryProps[]>([]);
  const [search, setSearch] = useState("");

  const badgeContext = useContext(BadgeContext);

  const logOut = () => {
    cookies.remove("token");
    cookies.remove("isLoggedIn");
    cookies.remove("fullName");
    window.location.replace("/login");
  };

  const handleSearchSubmit = () => {
    setSearch("");
    history.push("/search/" + search);
  };

  const handleClickIconSearch = () => {
    if (search !== "") {
      handleSearchSubmit();
    }
  };

  const handleEnter = (e: any) => {
    if (e.key === "Enter") {
      if (search !== "") {
        handleSearchSubmit();
      }
    }
  };

  useEffect(() => {
    const getBadge = async () => {
      await AxiosInstance.get("/customers/myprofile").then((res) => {
        if (res.status === 200 && res.data.count > 0) {
          setFullName(res.data.result[0].display_name);
          badgeContext.dispatch({
            type: "GENERATE",
            payload: {
              wishlist_qty: res.data.result[0].qty_wishlist,
              cart_qty: res.data.result[0].qty_cart,
            },
          });
        }
      });
    };
    if (loggedIn) {
      getBadge();
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedIn]);

  useEffect(() => {
    const getCateg = async () => {
      try {
        await axios
          .get(
            process.env.REACT_APP_MINUMMINUM_PUBLIC_API + "/ecommerce_category"
          )
          .then((res) => {
            setCategory(res.data.result);
          });
      } catch (error) {
        console.log(error);
      }
    };
    getCateg();
  }, []);

  let urlExtras: string[] | undefined;
  if (location.pathname !== "/") {
    urlExtras = location.pathname.split("/");
  }

  return (
    <NavbarRoot>
      <HeaderBox>
        <Container>
          <div className="bar">
            {/* Hide on mobile device */}
            <Hidden smDown>
              <Link to="/" className="logo">
                <picture>
                  <source srcSet="/assets/images/logo.webp" type="image/webp" />
                  <source srcSet="/assets/images/logo.png" type="image/png" />
                  <img
                    width="163"
                    height="60"
                    src="/assets/images/logo.png"
                    alt="Order Now"
                  />
                </picture>
              </Link>
            </Hidden>
            {/* Hide on desktop device */}
            <Hidden mdUp>
              <Link to="/profile" className="avatarProfile">
                <picture>
                  <source
                    srcSet="/assets/images/profile-photo-default.webp"
                    type="image/webp"
                  />
                  <source
                    srcSet="/assets/images/profile-photo-default.png"
                    type="image/png"
                  />
                  <img
                    src="/assets/images/profile-photo-default.png"
                    alt="Order Now"
                  />
                </picture>
              </Link>
            </Hidden>
            {/* Hide on mobile device */}
            <Hidden smDown>
              <div className="searchBox">
                <div className="searchInput">
                  <div onClick={handleClickIconSearch} className="searchIcon">
                    <SearchIcon />
                  </div>
                  <InputBase
                    placeholder="I'm looking for whisky..."
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput,
                    }}
                    value={search}
                    inputProps={{ "aria-label": "search" }}
                    onChange={(event) => {
                      setSearch(event.target.value);
                    }}
                    onKeyDown={handleEnter}
                  />
                </div>
              </div>
            </Hidden>
            <div className="menuNav">
              <Link to="/favorite">
                <IconButton aria-label="favorite">
                  <StyledBadge badgeContent={badgeContext.state.wishlist_qty}>
                    <FavoriteBorderIcon className="icon" />
                  </StyledBadge>
                </IconButton>
              </Link>
              {/* <IconButton aria-label="message">
                                <StyledBadge badgeContent={1}>
                                    <MailOutlineIcon className="icon"/>
                                </StyledBadge>
                            </IconButton> */}
              <Link to="/cart">
                <IconButton aria-label="cart">
                  <StyledBadge badgeContent={badgeContext.state.cart_qty}>
                    <ShoppingCartOutlinedIcon className="icon" />
                  </StyledBadge>
                </IconButton>
              </Link>
              <Link to="/purchase">
                <IconButton aria-label="notification">
                  <StyledBadge badgeContent={0}>
                    <NotificationsNoneOutlinedIcon className="icon" />
                  </StyledBadge>
                </IconButton>
              </Link>
            </div>
          </div>
          <Hidden mdUp>
            <MobileBoxProfile>
              {location.pathname === "/" && (
                <>
                  <div className="profile">
                    <BoxItem>
                      <ProfileBox
                        fullName={fullName}
                        loggedIn={loggedIn}
                        logOut={logOut}
                      />
                    </BoxItem>
                  </div>
                </>
              )}
              <div className="mobileConfig">
                {location.pathname !== "/" && (
                  <div className="backIconContainer">
                    <Link to={"/"}>
                      <ArrowBackIcon />
                    </Link>
                  </div>
                )}
                <div className="searchBox">
                  <div className="searchInput">
                    <div onClick={handleClickIconSearch} className="searchIcon">
                      <SearchIcon />
                    </div>
                    <InputBase
                      placeholder="I'm looking for whisky..."
                      classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                      }}
                      value={search}
                      inputProps={{ "aria-label": "search" }}
                      onChange={(event) => {
                        setSearch(event.target.value);
                      }}
                      onKeyDown={handleEnter}
                    />
                  </div>
                </div>
              </div>
            </MobileBoxProfile>
          </Hidden>
        </Container>
      </HeaderBox>
      <Hidden smDown>
        <DekstopHeader>
          <Container>
            <div className="content">
              <DesktopBoxProfile>
                <Link to="/profile" className="avatarProfile">
                  <picture>
                    <source
                      srcSet="/assets/images/profile-photo-default.webp"
                      type="image/webp"
                    />
                    <source
                      srcSet="/assets/images/profile-photo-default.png"
                      type="image/png"
                    />
                    <img
                      src="/assets/images/profile-photo-default.png"
                      alt="Order Now"
                    />
                  </picture>
                </Link>
                <div className="profile">
                  <DesktopBoxItem>
                    <ProfileBox
                      fullName={fullName}
                      loggedIn={loggedIn}
                      logOut={logOut}
                    />
                  </DesktopBoxItem>
                </div>
              </DesktopBoxProfile>
              <MenuCategory>
                {category.length > 0 &&
                  category.map((items, index) => (
                    <Link
                      key={index}
                      className={
                        urlExtras !== undefined
                          ? urlExtras[2] === items.display_name.toLowerCase()
                            ? "active"
                            : ""
                          : ""
                      }
                      to={`/collection/${items.display_name.toLowerCase()}/${
                        items.id
                      }`}
                    >
                      {items.display_name}
                    </Link>
                  ))}
                <Link
                  to="/story/tipsandtricks"
                  className={
                    urlExtras !== undefined
                      ? urlExtras[1] === "story"
                        ? "active"
                        : ""
                      : ""
                  }
                >
                  Extras
                </Link>
              </MenuCategory>
            </div>
          </Container>
        </DekstopHeader>
      </Hidden>
    </NavbarRoot>
  );
};

const NavbarRoot = styled.div`
  position: static;
  width: 100%;

  ${media.lessThan("small")`
        position: static;
        top: 0;
        z-index: 1000;
    `}
`;

const HeaderBox = styled.header`
  position: static;
  width: 100%;
  display: flex;
  padding: 18px 0;
  background-image: url("/assets/images/menu-bg-black.jpg");
  background-size: cover;
  background-color: #212121;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  transition: all 0.5s ease-in-out;

  .bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 64px;

    .logo {
      width: auto;
      height: 60px;

      img {
        height: 100%;
      }
    }

    .avatarProfile {
      padding: 5px;
      align-items: center;
      width: 60px;
      height: 60px;

      img {
        width: 60px;
        height: 60px;
        object-fit: cover;
      }

      ${media.lessThan("medium")`
                width: 50px;
                height: 50px;
                
                img {
                    width: 50px;
                    height: 50px;
                }
            `}
    }

    .menuNav {
      color: #f4c749;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .icon {
        color: #f4c749;
        font-size: 28px;

        ${media.lessThan("medium")`
                    font-size: 25px;
                `}
      }
    }
  }

  .mobileConfig {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 1rem;

    .backIconContainer {
      width: 25px;
      margin-right: 10px;
      a {
        color: #fff;
      }
    }
  }

  .searchBox {
    width: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 5px;
    transition: all 0.3s ease-in-out;

    ${media.lessThan("medium")`
            width: 100%;
        `}

    .searchInput {
      width: 400px;
      position: relative;

      ${media.lessThan("medium")`
                width: 100%;
            `}

      .searchIcon {
        color: #333;
        cursor: pointer;
        height: 100%;
        z-index: 2;
        position: absolute;
        left: 10px;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
`;

const MobileBoxProfile = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  .profile {
    color: #fff;
    margin-top: 1rem;
  }
`;

const DekstopHeader = styled.header`
  position: static;
  display: flex;
  padding: 10px;

  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 15px;
    border-bottom: 2px solid rgb(244, 199, 73);
  }
`;

const DesktopBoxProfile = styled.div`
  display: flex;
  padding: 5px;
  align-items: center;
  gap: 15px;

  .avatarProfile {
    padding: 5px;
    align-items: center;
    width: 75px;
    height: 75px;

    img {
      width: 75px;
      height: 75px;
      object-fit: cover;
    }
  }

  .profile {
    padding: 0;
  }
`;

const BoxItem = styled.div`
  line-height: 1.5;

  .name {
    margin: 0;
    font-size: 1rem;
    font-weight: normal;
  }

  .item {
    font-weight: normal;
    margin: 0;
    font-size: 0.875rem;
  }

  .link {
    color: #fff;
    text-decoration: underline;
    cursor: pointer;
  }
`;

const DesktopBoxItem = styled(BoxItem)`
  line-height: 1.35;
  .name {
    font-size: 1.25rem;
  }
  .item {
    font-size: 1rem;
  }
  .link {
    color: #000;
  }
`;

const MenuCategory = styled.div`
  position: relative;

  * {
    font-size: 1.25rem;
    color: #333;
    border: 2px solid transparent;
    padding: 10px 10px;
    position: relative;
    margin-left: 16px;
    text-transform: uppercase;
    text-decoration: none;

    :hover {
      color: #f4c749;
      border-top: 2px solid #f4c749;
      border-bottom: 2px solid #f4c749;
      text-decoration: none;

      &::before {
        position: absolute;
        content: "";
        left: -2px;
        top: 0px;
        height: 30%;
        width: 100%;
        border-left: 2px solid #f4c749;
        border-right: 2px solid #f4c749;
      }

      &::after {
        position: absolute;
        content: "";
        left: -2px;
        bottom: -1px;
        height: 30%;
        width: 100%;
        border-left: 2px solid #f4c749;
        border-right: 2px solid #f4c749;
      }
    }
  }

  .active {
    color: #f4c749;
    border-top: 2px solid #f4c749;
    border-bottom: 2px solid #f4c749;
    text-decoration: none;

    &::before {
      position: absolute;
      content: "";
      left: -2px;
      top: 0px;
      height: 30%;
      width: 100%;
      border-left: 2px solid #f4c749;
      border-right: 2px solid #f4c749;
    }

    &::after {
      position: absolute;
      content: "";
      left: -2px;
      bottom: -1px;
      height: 30%;
      width: 100%;
      border-left: 2px solid #f4c749;
      border-right: 2px solid #f4c749;
    }
  }
`;

export default Navigation;
