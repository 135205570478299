import { useState, useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { Typography, Grid } from '@material-ui/core/';

import { Link } from 'react-router-dom';
import axios from 'axios';

import Skeleton from '../../skeleton/Skeleton';
import { Tile, TipsTile } from '../container/Card';

import { StoryState } from '../../state/state';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingTop: '0',
            paddingBottom: '0',
        },
        link: {
            textDecoration: 'none',
            color: '#333',
        },
    }),
);

const Tips = () => {
    const classes = useStyles();

    const [blog, setBlog] = useState<StoryState[]>([]);
    const [loading, setLoading] = useState(false);

    let blogAPI = process.env.REACT_APP_MINUMMINUM_PUBLIC_API + '/blogs?find=tipsandtricks&limit=4&offset=1';

    useEffect(() => {
        const getBlog = async () => {
            try {
                await axios.get(blogAPI).then(res => { setBlog(res.data.result) });
            }
            catch (error) {
                setBlog([]);
                console.log("error blog");
            }
            setLoading(true);
        }
        getBlog();

        return () => {
            setBlog([]);
            setLoading(false);
        }
    }, [blogAPI]);

    return (
        <Grid container spacing={2}>
            {!loading && [1, 2, 3, 4].map((n) => <Grid item xs={12} key={n}><Skeleton type="tipsTricks" /></Grid>)}
            {loading && blog.length > 0 ?
                blog.map((items) => (
                    <Grid item xs={12} key={items.id}>
                        <Tile>
                            <Link to={`/story/tipsandtricks/${items.id}`} className={classes.link}>
                                <TipsTile
                                    cover={items.cover_properties === "" ? "https://static-mm.awan.id/web/image/" : items.cover_properties}
                                    title={items.display_name}
                                    __last_update={items.__last_update}
                                    description={items.description}
                                    content={items.content}
                                >
                                </TipsTile>
                            </Link>
                        </Tile>
                    </Grid>
                )) : <Grid item xs={6}><Typography variant="subtitle1">There is no blog in this parameter</Typography></Grid>}
        </Grid>
    )
}

const TipsTrick = () => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Typography variant="h6" gutterBottom>
                <Link className={classes.link} to='/story/tipsandtricks'>Tips & Tricks</Link>
            </Typography>
            <Tips />
        </div>
    );
}

export default TipsTrick;