import { useState, useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { Container, Typography, Grid } from '@material-ui/core';

import { Link } from 'react-router-dom';
import axios from 'axios';

import Skeleton from '../../skeleton/Skeleton';
import { Tile, VideoTile } from '../container/Card';

import { StoryState } from '../../state/state';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingTop: '1.5rem',
            paddingBottom: '1.5rem',
        },
        cardContainer: {
            display: 'flex',
            justifyContent: 'center',
            [theme.breakpoints.down('sm')]: {
                justifyContent: 'flex-start',
                flexWrap: 'nowrap',
                overflowX: 'auto',
                scrollbarWidth: 'none',
                '&::-webkit-scrollbar': {
                    display: 'none',  /* Safari and Chrome */
                },
            },
        },
        link: {
            textDecoration: 'none',
            color: '#333',
        },
    }),
);

function getWindowDimensions() {
    const { innerWidth: width } = window;
    let paddingX: number;
    if (width < 600) {
        paddingX = 16;
    }
    else if (width < 1280) {
        paddingX = 24;
    }
    else if (width < 1480) {
        paddingX = 40;
    }
    else {
        paddingX = 100;
    }
    return { width, paddingX };
}

const Video = () => {
    const classes = useStyles();
    const [blog, setBlog] = useState<StoryState[]>([]);
    const [loading, setLoading] = useState(false);

    let blogAPI = process.env.REACT_APP_MINUMMINUM_PUBLIC_API + '/blogs?find=top_video&limit=6&offset=1';

    useEffect(() => {
        const getBlog = async () => {
            try {
                await axios.get(blogAPI).then(res => { setBlog(res.data.result) });
            }
            catch (error) {
                setBlog([]);
                console.log("error blog");
            }
            setLoading(true);
        }
        getBlog();

        return () => {
            setBlog([]);
            setLoading(false);
        }
    }, [blogAPI]);

    return (
        <Grid container spacing={3} className={classes.cardContainer}>
            {!loading && [1, 2, 3, 4, 5, 6].map((n) => <Grid item xs={12} md={2} key={n}><Skeleton type="blog" /></Grid>)}
            {loading && blog.length > 0 ?
                blog.map((items) => (
                    <Grid item xs={12} md={2} key={items.id}>
                        <Tile>
                            <Link to={`/story/video/${items.id}`} className={classes.link}>
                                <VideoTile
                                    cover={items.cover_properties === "" ? "https://static-mm.awan.id/web/image/" : items.cover_properties}
                                    title={items.display_name}
                                    __last_update={items.__last_update}
                                    description={items.description}
                                    content={items.content}
                                >
                                </VideoTile>
                            </Link>
                        </Tile>
                    </Grid>
                )) : <Grid item xs={6}><Typography variant="subtitle1">There is no blog in this parameter</Typography></Grid>}
        </Grid>
    )
}

const Videos = () => {
    const classes = useStyles();

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const { paddingX } = windowDimensions;

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <div className={classes.root}>
            <Container>
                <Typography variant="h5" style={{ fontWeight: 'bold' }} gutterBottom>
                    <Link className={classes.link} to='/story/video'>Top Videos</Link>
                </Typography>
            </Container>
            <div style={{ padding: `0px ${paddingX}px` }}>
                <Video></Video>
            </div>
        </div>
    );
}

export default Videos;