import { Container } from "@material-ui/core";
import ProductsList from "../../components/ProductList";

const CollectionPage = () => {
	return (
		<Container>
			<ProductsList />
		</Container>
	)
}

export default CollectionPage;