import { useState, useEffect, useContext } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { Container, Typography, Grid, Snackbar } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import CloseIcon from '@material-ui/icons/Close';

import AxiosInstance from '../utils/axiosInstance';
import { Link } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import Cookies from 'universal-cookie';

import Skeleton from '../skeleton/Skeleton';
import { BadgeContext } from '../state/context';
import { PageHeading } from './heading/Heading';
import { Card, StickyCard, DataBox } from './container/Card';
import { AgreementInformation, CartAction, ContentCart, DataList, HighligthDataList, ImageCart } from './container/CartContainer';
import { CartFullButton, CountButton } from './DynamicButton';
import { Price, PromoPrice } from './container/ProductContainer';

import { CartState, ProductState } from '../state/state';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingBottom: '30px',
            minHeight: '50vh'
        },
        iconButton: {
            borderRadius: '8px',
            padding: '5px',
        },
    }),
);

const CartItem = ({ items, childChanged, stateDeleteCart }: any) => {
    const classes = useStyles();

    const [counter, setCounter] = useState(items.product_uom_qty);
    const context = useContext(BadgeContext);

    async function updateCart(quantity: number) {
        let res = await AxiosInstance.put('/sales_detail/update_cart', {
            "id": items.sale_detail_id,
            "productid": items.id,
            "price": items.promo_price !== 0 ? items.promo_price : items.list_price,
            "quantity": quantity
        });

        if (res.status === 200 && res.data.Status === true) {
            console.log(res.data.Message);
            childChanged();
        }
        else {
            console.log("Gagal update cart");
        }
    }

    async function deleteCart() {
        let res = await AxiosInstance.delete('/sales_detail/delete_cart/' + items.sale_detail_id);

        if (res.status === 200 && res.data.Status === true) {
            console.log(res.data.Message);
            childChanged();

            let updateBadge = await AxiosInstance.get('/customers/myprofile');
            if (updateBadge.status === 200 && updateBadge.data.count > 0) {
                context.dispatch({
                    type: 'GENERATE',
                    payload: {
                        wishlist_qty: updateBadge.data.result[0].qty_wishlist,
                        cart_qty: updateBadge.data.result[0].qty_cart,
                    }
                });
                stateDeleteCart({ open: true, message: items.display_name + " was deleted successfully from your cart." });
            }
        }
        else {
            console.log(res.data);
        }
    }

    const decrement = () => {
        updateCart(counter - 1);
        setCounter(counter - 1);
    }

    const increment = () => {
        updateCart(counter + 1);
        setCounter(counter + 1);
    }

    const removeCart = () => {
        deleteCart();
    }

    return (
        <Grid item xs={12} md={12} key={items.name}>
            <Card>
                <ContentCart>
                    <ImageCart>
                        <img src={items.image_256} alt="" />
                    </ImageCart>
                    <div className="content">
                        <Link to={`/product/${items.categ_id[1].split(" / ")[1] !== undefined ? items.categ_id[1].split(" / ")[1].toLowerCase() : items.categ_id[1].toLowerCase()}/${items.id}`}>
                            <h4>{items.display_name}</h4>
                        </Link>
                        <p className="desc">
                            {items.description !== "" && items.description !== false ?
                                items.description.length > 140 ?
                                    items.description.replace(/(<([^>]+)>)/gi, "").slice(0, 140) + "..."
                                    : items.description.replace(/(<([^>]+)>)/gi, "")
                                : items.description
                            }
                        </p>
                        <div>
                            <CartAction>
                                <IconButton onClick={removeCart} aria-label="delete" disableRipple className={classes.iconButton}>
                                    <DeleteOutlineOutlinedIcon />
                                </IconButton>
                                <div className="counter">
                                    <CountButton disabled={counter === 1} onClick={decrement}>-</CountButton>
                                    <p>{counter}</p>
                                    <CountButton disabled={false} onClick={increment}>+</CountButton>
                                </div>
                            </CartAction>
                            {items.promo_price !== 0 ?
                                <>
                                    <PromoPrice type="medium"><span>{items.currency_id[1]} <NumberFormat value={items.list_price} thousandSeparator="." decimalSeparator="," displayType={'text'} /></span></PromoPrice>
                                    <Price type="medium">{items.currency_id[1]} <NumberFormat value={items.promo_price} thousandSeparator="." decimalSeparator="," displayType={'text'} /></Price>
                                </>
                                : <Price type="medium">{items.currency_id[1]} <NumberFormat value={items.list_price} thousandSeparator="." decimalSeparator="," displayType={'text'} /></Price>
                            }
                        </div>
                    </div>
                </ContentCart>
            </Card>
        </Grid>
    )
}

const Cart = () => {
    const classes = useStyles();
    const cookies = new Cookies();

    const [token] = useState(cookies.get('token'));
    const [loading, setLoading] = useState({ cart: false, product: false });
    const [changed, setChanged] = useState(false);
    const [cart, setCart] = useState<CartState>({
        id: 0,
        date_order: '',
        amount_total: 0,
        amount_tax: 0,
        amount_untaxed: 0,
        cart_quantity: 0,
        currency_id: [0, '']
    });
    const [product, setProduct] = useState<ProductState[]>([]);
    const [snackbar, setSnackbar] = useState({ open: false, message: "" });

    const childChanged = () => {
        setChanged(!changed);
    }

    useEffect(() => {
        const getCart = async () => {
            await AxiosInstance.get('/sales?find=cart').then(
                res => {
                    if (res.status === 200 && res.data.count !== 0) {
                        setCart(res.data.result);
                    }
                    else {
                        setCart(c => { return ({ ...c }) });
                    }
                }
            );
            setLoading(l => { return ({ ...l, cart: true }) });
        }
        if (token) { getCart() } else { setCart(c => { return ({ ...c }) }) }
        return () => { setCart(c => { return ({ ...c }) }) }
    }, [changed, token])

    useEffect(() => {
        const getProduct = async () => {
            await AxiosInstance.get('/sales_detail/' + cart.id).then(
                res => {
                    if (res.status === 200 && res.data.count >= 0) {
                        setProduct(res.data.result);
                    }
                    else {
                        setProduct([]);
                    }
                }
            );

            setLoading({ cart: true, product: true });
        }
        if (cart.id !== 0) { getProduct() }
        return () => { }
    }, [cart.id, cart.cart_quantity])

    return (
        <Container className={classes.root}>
            <PageHeading text="My Cart" />
            <Grid container spacing={3} >
                <Grid item xs={12} md={7}>
                    <Grid container spacing={3}>
                        {!token ?
                            <Grid item xs={12}><Typography variant="h6">You must logged in to see your cart, <Link to="/login">login here</Link></Typography></Grid>
                            : !loading.product ?
                                [1, 2, 3].map((n) => <Grid key={n} item xs={12} md={12}><Skeleton type="cart" /></Grid>)
                                : cart.cart_quantity > 0 ?
                                    product.map((items) => (<CartItem items={items} key={items.id} childChanged={childChanged} stateDeleteCart={setSnackbar}></CartItem>))
                                    : <Grid item xs={12}><Typography variant="h6">Your cart is empty</Typography></Grid>
                        }
                    </Grid>
                </Grid>
                <Grid item xs={12} md={5}>
                    {token && !loading.product ? <Skeleton type="cartBox" /> : ''}
                    {loading.product && cart.cart_quantity > 0 ?
                        <StickyCard>
                            <DataList>
                                <h4>Cart Summary ({cart.cart_quantity} {cart.cart_quantity > 1 ? 'items' : 'item'})</h4>
                            </DataList>
                            {product.length > 0 && product.map((items, index) => (
                                <DataList key={index}>
                                    <h5>{items.display_name} {items.product_uom_qty > 1 ? <b>({items.product_uom_qty} items)</b> : ''}</h5>
                                    <p>{items.currency_id[1]} <NumberFormat value={items.price_subtotal} thousandSeparator="." decimalSeparator="," displayType={'text'} /></p>
                                </DataList>
                            ))}
                            <DataList>
                                <h5>Subtotal</h5>
                                <p>{cart.currency_id[1]} <NumberFormat value={cart.amount_untaxed} thousandSeparator="." decimalSeparator="," displayType={'text'} /></p>
                            </DataList>
                            <DataList>
                                <h5>Tax</h5>
                                <p>{cart.currency_id[1]} <NumberFormat value={cart.amount_tax} thousandSeparator="." decimalSeparator="," displayType={'text'} /></p>
                            </DataList>
                            <HighligthDataList>
                                <h5>Total Price</h5>
                                <p>{cart.currency_id[1]} <NumberFormat value={cart.amount_total} thousandSeparator="." decimalSeparator="," displayType={'text'} /></p>
                            </HighligthDataList>
                            <AgreementInformation>
                                In accordance with the laws of the Republic of Indonesia, by clicking the button below, you acknowledge that you are <b>21 years or older.</b>
                            </AgreementInformation>
                            <DataBox pd="0.5rem 0" />
                            <Link to="/checkout">
                                <CartFullButton>Checkout</CartFullButton>
                            </Link>
                        </StickyCard>
                        : ''}
                </Grid>
            </Grid>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                open={snackbar.open}
                autoHideDuration={5000}
                onClose={() => setSnackbar({ open: false, message: "" })}
                message={snackbar.message}
                action={
                    <>
                        <IconButton aria-label="close snackbar" disableRipple onClick={() => setSnackbar({ open: false, message: "" })}>
                            <CloseIcon fontSize="small" style={{ color: '#FFF' }} />
                        </IconButton>
                    </>
                }
            />
        </Container>
    );
}

export default Cart;