import { Container, Grid, Typography } from "@material-ui/core";

import Banner from "../components/banner/Banner";
import News from "../components/extras/News";
import TipsTrick from "../components/extras/TipsTrick";
import Video from '../components/extras/Video';
import ProductsList from "../components/ProductList";

import { PageHeading } from "../components/heading/Heading";

const Home = () => {
    return (
        <>
            <Banner />
            {/* <CategoryList /> */}
            <Container>
                <PageHeading text="Best Seller" />
                <ProductsList type="bestseller" />
                <PageHeading text="The Story About Booze" />
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <TipsTrick />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <News />
                    </Grid>
                </Grid>
            </Container>
            <Video />
            <Container>
                <PageHeading text="Merchandise" />
                <Typography variant="subtitle1" gutterBottom>
                    We make it almost impossibly to find alcohol gift - that's we gather all the merchandise. Find the merchandise gifts below and add it to you collections.
                </Typography>
                <ProductsList type="merchandise" />
            </Container>
        </>
    )
}

export default Home;