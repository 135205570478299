import { FC, ReactElement, useState, useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { Container, Typography } from '@material-ui/core';

import { useParams } from 'react-router-dom';
import Skeleton from '../skeleton/Skeleton';
import { PageHeading } from './heading/Heading';

import { RouteState, StoryState } from '../state/state';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingBottom: '30px',
            minHeight: '50vh',
        },
        storyContent: {
            display: 'block',
            overflow: 'hidden'
        }
    }),
);

const Content = () => {
    const params = useParams<RouteState>();
    const classes = useStyles();

    const [blog, setBlog] = useState<StoryState[]>([]);
    const [loading, setLoading] = useState(false);

    const url = process.env.REACT_APP_MINUMMINUM_PUBLIC_API + '/blogs?find=' + params.id;

    useEffect(() => {
        const getBlog = async () => {
            try {
                const DataBlog = await (await fetch(url)).json();
                setBlog(DataBlog.result);
                setLoading(true);
            }
            catch (error) {
                setBlog([]);
                console.log("error product");
            }
        }
        getBlog();
        return () => {
            setLoading(false);
            setBlog([]);
        }
    }, [url]);

    return (
        <div className={classes.storyContent}>
            {!loading && <Skeleton type="blogDetail"></Skeleton>}
            {loading && blog.length > 0 ?
                blog.map((items) => (
                    <div key={items.id}>
                        <PageHeading text={items.display_name} />
                        {/* <Typography variant="subtitle1" gutterBottom>
                            {items.__last_update} on <Link className={classes.link} to={`/story/${items.blog_id[1]}`}>{items.blog_id[1]}</Link>
                        </Typography> */}
                        <Typography dangerouslySetInnerHTML={{ __html: items.content }}></Typography>
                    </div>
                )) : <Typography variant="subtitle1">There is no pages in this parameter</Typography>}
        </div>
    )
}

const PageContent: FC<{}> = (): ReactElement => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Container>
                <Content></Content>
            </Container>
        </div>
    );
}

export default PageContent;