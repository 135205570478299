import { FC, ReactElement, useState, useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { Grid, Typography } from '@material-ui/core';

import { Link, useParams } from 'react-router-dom';
import axios from 'axios';

import Skeleton from '../skeleton/Skeleton';
import { ScrolledGrid, StoryGrid, Tile } from './container/Card';

import { RouteState, StoryState } from '../state/state';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingTop: '0px',
            paddingBottom: '30px',
        },
        link: {
            textDecoration: 'none',
            color: '#333',
            width: '100%',
        },
        storyContent: {
            display: 'block',
            overflow: 'hidden'
        }
    }),
);

const StoryContent = () => {
    const params = useParams<RouteState>();
    const classes = useStyles();

    const [blog, setBlog] = useState<StoryState[]>([]);
    const [loading, setLoading] = useState(false);

    const url = process.env.REACT_APP_MINUMMINUM_PUBLIC_API + '/blogs/' + params.id;

    useEffect(() => {
        const getBlog = async () => {
            try {
                await axios.get(url).then(res => { setBlog(res.data.result) });
            }
            catch (error) {
                setBlog([]);
                console.log("error product");
            }
            setLoading(true);
        }
        getBlog();
        return () => {
            setLoading(false);
            setBlog([]);
        }
    }, [url]);

    return (
        <div className={classes.storyContent}>
            {!loading && <Skeleton type="blogDetail"></Skeleton>}
            {loading && blog.length > 0 ?
                blog.map((items) => (
                    <div key={items.id}>
                        <Typography variant="h5" style={{ fontWeight: 'bold' }}>
                            {items.display_name}
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom>
                            {items.__last_update} on <Link className={classes.link} to={`/story/${items.blog_id[1] === 'Tips & Tricks' ? 'tipsandtricks' : items.blog_id[1] === 'News' ? 'news' : 'video'}`}>{items.blog_id[1]}</Link>
                        </Typography>
                        <Typography dangerouslySetInnerHTML={{ __html: items.content }}></Typography>
                    </div>
                )) : <Typography variant="subtitle1">There is no blog in this parameter</Typography>}
        </div>
    )
}

const StoryList = () => {
    const classes = useStyles();
    const params = useParams<RouteState>();
    const [blog, setBlog] = useState<StoryState[]>([]);
    const [loading, setLoading] = useState(false);

    let blogAPI = process.env.REACT_APP_MINUMMINUM_PUBLIC_API + '/blogs?find=tipsandtricks';

    if (params.category === 'tipsandtricks') {
        blogAPI = process.env.REACT_APP_MINUMMINUM_PUBLIC_API + '/blogs?find=tipsandtricks'
    }
    else if (params.category === 'news') {
        blogAPI = process.env.REACT_APP_MINUMMINUM_PUBLIC_API + '/blogs?find=news'
    }
    else if (params.category === 'video') {
        blogAPI = process.env.REACT_APP_MINUMMINUM_PUBLIC_API + '/blogs?find=top_video'
    }

    useEffect(() => {
        const getBlog = async () => {
            try {
                await axios.get(blogAPI).then(res => { setBlog(res.data.result) });
            }
            catch (error) {
                setBlog([]);
                console.log("error blog");
            }
            setLoading(true);
        }
        getBlog();
        return () => {
            setLoading(false);
            setBlog([]);
        }
    }, [blogAPI]);

    return (
        <Grid container spacing={3}>
            {!loading && [1, 2, 3, 4].map((n) => <Grid key={n} item xs={6} md={12}><Skeleton type="blog" /></Grid>)}
            {loading && blog.length > 0 ?
                blog.map((items) => (
                    <Grid item xs={6} md={12} key={items.id}>
                        <Tile>
                            <Link to={`/story/${items.blog_id[1] === 'Tips & Tricks' ? 'tipsandtricks' : items.blog_id[1] === 'News' ? 'news' : 'video'}/${items.id}`} className={classes.link}>
                                <StoryGrid
                                    cover={items.cover_properties === "" ? "https://static-mm.awan.id/web/image/" : items.cover_properties}
                                    title={items.display_name}
                                    __last_update={items.__last_update}
                                    description={items.description}
                                    content={items.content}
                                >
                                </StoryGrid>
                            </Link>
                        </Tile>
                    </Grid>
                )) : <Grid item xs={6}><Typography variant="subtitle1">There is no blog in this parameter</Typography></Grid>}
        </Grid>
    )
}

const StoryPageDetail: FC<{}> = (): ReactElement => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Grid container spacing={4}>
                <Grid item xs={12} md={9}>
                    <StoryContent />
                </Grid>
                <Grid item xs={12} md={3}>
                    <Typography variant="h6" style={{ fontWeight: 'bold', marginTop: '30px', marginBottom: '20px', color: '#999' }} gutterBottom>
                        Latest Post ———————————
                    </Typography>
                    <ScrolledGrid>
                        <StoryList></StoryList>
                    </ScrolledGrid>
                </Grid>
            </Grid>
        </div>
    );
}

export default StoryPageDetail;