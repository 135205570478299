import axios from 'axios';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import dayjs from 'dayjs';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const baseURL: string | undefined  = process.env.REACT_APP_MINUMMINUM_PUBLIC_API;
let authToken = cookies.get('token') ? cookies.get('token') : null;

const AxiosInstance = axios.create({
    baseURL,
    headers: {
        Authorization: `Bearer ${authToken}`,
    }
});

AxiosInstance.interceptors.request.use(async req => {
    if (!authToken) {
        authToken = cookies.get('token') ? cookies.get('token') : null;
        req.headers.Authorization = `Bearer ${authToken}`;
    }

    let isExpired = true;

    try {
        const user = jwtDecode<JwtPayload>(authToken);
        isExpired = dayjs.unix(Number(user.exp)).diff(dayjs()) < 1;
    } catch (error) {
        console.log(error);
    }

    if (!isExpired) {
        // Refresh Token
        try {
            const response = await axios.post(
                `${baseURL}/customers/refresh`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    }
                }
            );

            if (response.status === 200) {
                cookies.set('token', response.data.result.access_token, { path: '/' });
                cookies.set('fullName', response.data.result.full_name, { path: '/' });
                cookies.set('isLoggedIn', true, { path: '/' });
                authToken = cookies.get('token');
            }

        } catch (error) {
            cookies.remove('token');
            cookies.remove('isLoggedIn');
            cookies.remove('fullName');
            window.location.reload();
        }
    }
    
    else {
        cookies.remove('token');
        cookies.remove('isLoggedIn');
        cookies.remove('fullName');
        window.location.reload();
    }

    return req
});

export default AxiosInstance;