import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  Container,
  Typography,
  Grid,
  Box,
  IconButton,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { WhatsApp, Email, YouTube, Instagram } from "@material-ui/icons/";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: "20px",
      backgroundColor: "#FAF4E4",
      borderBottom: "10px solid #FCC201",
      padding: "40px",
    },
    copyrightContainer: {
      marginTop: "20px",
      textAlign: "center",
    },
    title: {
      fontWeight: "bold",
    },
    link: {
      textDecoration: "none",
      color: "#333",
      "&:hover": {
        textDecoration: "underline",
      },
    },
    paymentImageContent: {
      display: "flex",
      marginTop: "0.25rem",
      width: "200px",
    },
    contactUsLink: {
      textDecoration: "none",
      color: "#333",
      "&:hover": {
        textDecoration: "underline",
      },
      display: "flex",
      gap: theme.spacing(1),
      alignItems: "center",
      marginBottom: "0.35em",
    },
    actionButtonSocialMedia: {
      display: "flex",
      marginBottom: theme.spacing(2),
      gap: theme.spacing(1.5),
    },
    socialMediaButton: {
      textTransform: "unset",
      borderRadius: "0.45em",
      color: "#333",
      transition: "all .25s ease-in-out",
      background: "transparent",
      padding: 0,
      "&:hover": {
        background: "transparent",
      },
    },
  })
);

const Footer = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} md={3}>
            <Typography variant="h6" className={classes.title} gutterBottom>
              Follow Us
            </Typography>
            <Box className={classes.actionButtonSocialMedia}>
              <a
                href="https://www.youtube.com/@minumminum9876"
                target="_blank"
                rel="noreferrer"
              >
                <IconButton
                  className={classes.socialMediaButton}
                  aria-label="Youtube"
                >
                  <YouTube />
                </IconButton>
              </a>
              <a
                href="https://www.instagram.com/minumminumcom/"
                target="_blank"
                rel="noreferrer"
              >
                <IconButton
                  className={classes.socialMediaButton}
                  aria-label="Instagram"
                >
                  <Instagram />
                </IconButton>
              </a>
            </Box>
            <Typography variant="h6" className={classes.title} gutterBottom>
              Payment Option
            </Typography>
            <picture>
              <source
                className={classes.paymentImageContent}
                srcSet="/assets/images/payment-image.webp"
                type="image/webp"
              />
              <source
                className={classes.paymentImageContent}
                srcSet="/assets/images/payment-image.png"
                type="image/png"
              />
              <img
                width="200"
                height="49"
                className={classes.paymentImageContent}
                src="/assets/images/payment-image.png"
                alt="Order Now"
              />
            </picture>
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography variant="h6" className={classes.title} gutterBottom>
              Guidance
            </Typography>
            <Link to="/pages/about_us" className={classes.link}>
              <Typography variant="subtitle1" gutterBottom>
                About us
              </Typography>
            </Link>
            <Link to="/pages/term_and_condition" className={classes.link}>
              <Typography variant="subtitle1" gutterBottom>
                Term & Condition
              </Typography>
            </Link>
            <Link to="/pages/privacy_policy" className={classes.link}>
              <Typography variant="subtitle1" gutterBottom>
                Privacy Policy
              </Typography>
            </Link>
            <Link to="/pages/faq" className={classes.link}>
              <Typography variant="subtitle1" gutterBottom>
                FAQ
              </Typography>
            </Link>
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography variant="h6" className={classes.title} gutterBottom>
              Contact Us
            </Typography>
            <a
              href="https://wa.me/6281383661978"
              target="_blank"
              rel="noreferrer"
              className={classes.contactUsLink}
            >
              <WhatsApp />
              <Typography variant="subtitle1">+62 8138 3661 978</Typography>
            </a>
            <a
              href="mailto:minumuminum@gmail.com"
              className={classes.contactUsLink}
            >
              <Email />
              <Typography variant="subtitle1">minumuminum@gmail.com</Typography>
            </a>
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography variant="h6" className={classes.title} gutterBottom>
              Resellers Program
            </Typography>
            <Link to="/pages/for_business" className={classes.link}>
              <Typography variant="subtitle1" gutterBottom>
                JOIN NOW!
              </Typography>
            </Link>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="subtitle1"
              gutterBottom
              className={classes.copyrightContainer}
            >
              <Link to="/pages/privacy_policy" className={classes.link}>
                Privacy Policy
              </Link>{" "}
              |{" "}
              <Link to="/pages/term_and_condition" className={classes.link}>
                Terms of service
              </Link>{" "}
              | © Minumminum.com All Right Reserved
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Footer;
