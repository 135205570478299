import { useState, useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Container, Typography, Grid } from '@material-ui/core';
import RoomIcon from '@material-ui/icons/Room';

import { Redirect } from "react-router-dom";
import axios from 'axios';
import AxiosInstance from '../utils/axiosInstance';
import styled from 'styled-components';
import { generateMedia } from "styled-media-query";

import Cookies from 'universal-cookie';
import Skeleton from '../skeleton/Skeleton';
import { ActionButton, ButtonGroup } from './DynamicButton';
import { TextInput, SelectOption } from './form/FormInput';
import { ModalHeading, ProfileHeading } from './heading/Heading';
import { AddressBox, Card, PinpointStatus } from './container/Card';
import { ButtonForm, CancelButton, GroupButtonForm } from './form/ButtonForm';
import { Modal, ModalBody } from './container/Modal';
import { Notification } from './notification/Notification';
import { AddressState, CityState, DistrictState, KelurahanState, ProvinceState, UserState } from '../state/state';
import { Maps } from './Maps';

const customMedia = generateMedia({
    desktop: "960px",
});

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingTop: '30px',
            paddingBottom: '30px',
            minHeight: '70vh',
        },
    }),
);

const AddressType = [{
    name: "Delivery Address",
    id: 'delivery'
},
{
    name: "Other Address",
    id: 'other'
}, {
    name: "Contact Address",
    id: 'contact'
}];

const EditAddress = ({ data, province, childChanged }: any) => {
    const [open, setOpen] = useState(false);
    const [status, setStatus] = useState({ status: false, message: '' });
    const [city, setCity] = useState<CityState[]>([]);
    const [district, setDistrict] = useState<DistrictState[]>([]);
    const [kelurahan, setKelurahan] = useState<KelurahanState[]>([]);
    const [dataUpdate, setDataUpdate] = useState({
        id: data.id,
        contact_name: data.name,
        addr_type: data.type,
        job_position: "",
        street: data.street,
        street2: data.street2,
        phone: data.phone,
        mobile: data.mobile,
        zip: data.zip,
        city: data.city,
        city_id: data.city_id[0],
        district_id: data.district_id[0],
        urban_village_id: data.urban_village_id[0],
        state_id: data.state_id[0],
        state_name: String(data.state_id !== false ? data.state_id[1].split(' (')[0] : ""),
        destination_coordinate: data.destination_coordinate,
    })

    const [pinpoint, setPinpoint] = useState({
        lat: dataUpdate.destination_coordinate !== false ? Number(dataUpdate.destination_coordinate.split(", ")[0]) : 0,
        lng: dataUpdate.destination_coordinate !== false ? Number(dataUpdate.destination_coordinate.split(", ")[1]) : 0
    });

    useEffect(() => {

        // reset selected city
        setCity([]);

        const getCity = async () => {
            await axios.post(process.env.REACT_APP_MINUMMINUM_PUBLIC_API + '/state/city_by_province', {
                "provinceName": dataUpdate.state_name
            }).then(
                res => {
                    if (res.data.count === 0) {
                        setCity([]);
                    }
                    else {
                        if (res.data.result !== "No city associated with province id: 0") {
                            setCity(res.data.result);
                        }
                        else {
                            setCity([]);
                        }
                        // setDataUpdate(p => { return ({ ...p, city: res.data.result[0].cityName + "#" + res.data.result[0].id }) });
                    }
                }
            )
        }
        if (dataUpdate.state_name !== undefined || dataUpdate.state_name !== "") {
            getCity();
        }
        else {
            setCity([]);
        }
        return () => { setCity([]) }
    }, [dataUpdate.state_name]);

    useEffect(() => {

        // reset selected district
        setDistrict([]);

        const getCity = async () => {
            await axios.get(process.env.REACT_APP_MINUMMINUM_PUBLIC_API + '/state/district_by_city/' + dataUpdate.city_id).then(
                res => {
                    if (res.data.count === 0) {
                        setDistrict([]);
                    }
                    else {
                        setDistrict(res.data.result);
                        // setDataUpdate(p => { return ({ ...p, city: res.data.result[0].cityName + "#" + res.data.result[0].id }) });
                    }
                }
            )
        }

        if (dataUpdate.city_id !== undefined && dataUpdate.city_id !== 0) {
            getCity();
        }

        return () => { setDistrict([]) }
    }, [dataUpdate.city_id]);

    useEffect(() => {

        // reset selected kelurahan
        setKelurahan([]);

        const getKelurahan = async () => {
            await axios.get(process.env.REACT_APP_MINUMMINUM_PUBLIC_API + '/state/kelurahan_by_district/' + dataUpdate.district_id).then(
                res => {
                    if (res.data.count === 0) {
                        setKelurahan([]);
                    }
                    else {
                        setKelurahan(res.data.result);
                        // setDataUpdate(p => { return ({ ...p, city: res.data.result[0].cityName + "#" + res.data.result[0].id }) });
                    }
                }
            )
        }

        if (dataUpdate.district_id !== undefined && dataUpdate.district_id !== 0) {
            getKelurahan();
        }
        return () => { setKelurahan([]) }
    }, [dataUpdate.district_id]);

    const editProfile = async () => {
        await AxiosInstance.put(process.env.REACT_APP_MINUMMINUM_PUBLIC_API + '/customers/address?id=' + dataUpdate.id, dataUpdate).then(
            res => {
                if (res.data.Status === true) {
                    setStatus({ status: true, message: res.data.result.Message });
                    setTimeout(
                        () => childChanged(),
                        2000
                    );
                }
                else {
                    setStatus({ status: true, message: res.data.result.Message });
                }
            }
        )
    }

    const handleInputChange = (event: any) => {
        setDataUpdate({ ...dataUpdate, [event.target.name]: event.target.value })
    }

    const handleInputProvince = (event: any) => {
        var index = event.target.selectedIndex;
        setDataUpdate({ ...dataUpdate, state_id: Number(event.target.value), state_name: event.target[index].text, city_id: 0, district_id: 0, urban_village_id: 0 });
    }

    const handleInputCity = (event: any) => {
        var index = event.target.selectedIndex;
        setDataUpdate({ ...dataUpdate, city: event.target[index].text, city_id: Number(event.target[index].value), district_id: 0, urban_village_id: 0 });
    }

    const handleInputDistrict = (event: any) => {
        var index = event.target.selectedIndex;
        setDataUpdate({ ...dataUpdate, district_id: Number(event.target[index].value), urban_village_id: 0 });
    }

    const handleInputKelurahan = (event: any) => {
        var index = event.target.selectedIndex;
        setDataUpdate({ ...dataUpdate, urban_village_id: Number(event.target[index].value), zip: Number(kelurahan.filter((e) => (e.id === Number(event.target[index].value))).map((e) => e.postalCode)) });
    }

    const handleClick = (event: any) => {
        event.preventDefault();
        editProfile();
    }

    useEffect(() => {
        setDataUpdate(d => { return ({ ...d, destination_coordinate: String(pinpoint.lat + ', ' + pinpoint.lng) }) })

        return () => { }
    }, [pinpoint])

    return (
        <>
            <ActionButton label="Edit" type="secondary" onClick={() => setOpen(!open)} />
            <Modal open={open} onClose={() => setOpen(!open)}>
                <ModalHeading text="Edit Address" />
                <form onSubmit={handleClick}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <SelectOption onInputChange={handleInputChange} required={true} name="addr_type" label="Address Type" data={AddressType} value={dataUpdate.addr_type} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextInput onInputChange={handleInputChange} required={true} name="contact_name" label="Contact Name" placeholder="Contact Name" value={dataUpdate.contact_name} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextInput onInputChange={handleInputChange} required={true} name="phone" label="Phone" placeholder="Phone" value={dataUpdate.phone} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextInput onInputChange={handleInputChange} required={true} name="mobile" label="Mobile" placeholder="Mobile" value={dataUpdate.mobile} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextInput onInputChange={handleInputChange} required={true} name="street" label="Street Address" placeholder="Street Address" value={dataUpdate.street} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextInput onInputChange={handleInputChange} required={true} name="street2" label="Street Address 2" placeholder="Street Address 2" value={dataUpdate.street2} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <SelectOption onInputChange={handleInputProvince} required={true} name="state_id" label="Province" data={province} value={dataUpdate.state_id} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <SelectOption onInputChange={handleInputCity} required={true} name="city" label="City" data={city.map(item => { return { name: item.cityName, id: item.id } })} value={dataUpdate.city_id} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <SelectOption onInputChange={handleInputDistrict} required={true} name="district" label="District" data={district.map(item => { return { name: item.districtName, id: item.id } })} value={dataUpdate.district_id} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <SelectOption onInputChange={handleInputKelurahan} required={true} name="kelurahan" label="Urban Village" data={kelurahan.map(item => { return { name: item.kelurahanName, id: item.id } })} value={dataUpdate.urban_village_id} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextInput onInputChange={handleInputChange} required={true} name="zip" label="Zip Code" placeholder="Zip code" value={dataUpdate.zip} />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Typography variant="subtitle1" gutterBottom>
                                Pinpoint
                            </Typography>
                            <Maps onClick={setPinpoint}
                                name={dataUpdate.contact_name}
                                lat={pinpoint.lat}
                                lng={pinpoint.lng} />
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="flex-end" spacing={2}>
                        <Grid item xs={12}>
                            {status.status && <Notification type="info" text={status.message} />}
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <GroupButtonForm>
                                <CancelButton onClick={() => setOpen(false)} label="Cancel"></CancelButton>
                                <ButtonForm label="Update"></ButtonForm>
                            </GroupButtonForm>
                        </Grid>
                    </Grid>
                </form>
            </Modal>
        </>
    )
}

const DeleteAddress = (props: { id: number, type: string, childChanged: any }) => {
    const [openModalDelete, setOpenModalDelete] = useState(false);
    const [status, setStatus] = useState({ status: false, message: '' });
    const deleteAddress = async (id: number) => {
        await AxiosInstance.delete(process.env.REACT_APP_MINUMMINUM_PUBLIC_API + '/customers/address?id=' + id).then(
            res => {
                if (res.data.Status === true) {
                    console.log(res.data);
                    setStatus({ status: true, message: res.data.result.Message });
                    setTimeout(
                        () => props.childChanged(),
                        2000
                    );
                }
                else {
                    console.log(res.data);
                }
            })
    }

    return (
        <>
            <ActionButton label="Delete" type="secondary" onClick={() => setOpenModalDelete(!openModalDelete)} />
            <Modal open={openModalDelete} onClose={() => setOpenModalDelete(!openModalDelete)}>
                <ModalHeading text="Delete Address" />
                <ModalBody>
                    <Typography variant="subtitle1" align={"center"} gutterBottom>
                        Are you sure you want to delete the <b>address {props.type}</b>? you can not return it again after deleting this address.
                    </Typography>
                    {status.status && <Notification type="info" text={status.message} />}
                </ModalBody>
                <GroupButtonForm>
                    <CancelButton onClick={() => setOpenModalDelete(false)} label="Cancel"></CancelButton>
                    <ButtonForm label="Delete" onClick={() => deleteAddress(props.id)}></ButtonForm>
                </GroupButtonForm>
            </Modal>
        </>
    )
}

const Profile = () => {
    const cookies = new Cookies();

    const [user, setUser] = useState<UserState[]>([]);
    const [loading, setLoading] = useState({ profile: false, address: false });
    const [status, setStatus] = useState({ status: false, message: '' });
    const [changed, setChanged] = useState(false);

    const [openModalAdd, setOpenModalAdd] = useState(false);
    const [openModalChangePw, setOpenModalChangePw] = useState(false);

    const [province, setProvince] = useState<ProvinceState[]>([]);
    const [city, setCity] = useState<CityState[]>([]);
    const [district, setDistrict] = useState<DistrictState[]>([]);
    const [kelurahan, setKelurahan] = useState<KelurahanState[]>([]);

    const [address, setAddress] = useState<AddressState[]>([]);
    const initialAddAddressState = {
        contact_name: '',
        addr_type: '',
        job_position: '',
        phone: '',
        mobile: '',
        street: '',
        street2: '',
        state_id: 0,
        state_name: '',
        city: '',
        city_id: 0,
        district_id: 0,
        urban_village_id: 0,
        zip: 0,
        destination_coordinate: '',
    }
    const [addAddress, setAddAddress] = useState(initialAddAddressState);

    const [dataPass, setDataPass] = useState({
        username: '',
        password: '',
        newpassword: '',
    });
    const [passwordWarn, setPasswordWarn] = useState('');
    const [pinpoint, setPinpoint] = useState({ lat: 0, lng: 0 });

    const childChanged = () => {
        setChanged(!changed);
    }

    const handleInputChange = (event: any) => {
        setAddAddress({ ...addAddress, [event.target.name]: event.target.value })
    }

    const handleInputProvince = (event: any) => {
        var index = event.target.selectedIndex;
        setAddAddress({ ...addAddress, state_id: Number(event.target.value), state_name: event.target[index].text, city_id: 0, district_id: 0, urban_village_id: 0 });
    }

    const handleInputCity = (event: any) => {
        var index = event.target.selectedIndex;
        setAddAddress({ ...addAddress, city: event.target[index].text, city_id: Number(event.target[index].value), district_id: 0, urban_village_id: 0 });
    }

    const handleInputDistrict = (event: any) => {
        var index = event.target.selectedIndex;
        setAddAddress({ ...addAddress, district_id: Number(event.target[index].value), urban_village_id: 0 });
    }

    const handleInputKelurahan = (event: any) => {
        var index = event.target.selectedIndex;
        setAddAddress({ ...addAddress, urban_village_id: Number(event.target[index].value), zip: Number(kelurahan.filter((e) => (e.id === Number(event.target[index].value))).map((e) => e.postalCode)) });
    }

    const handleClick = (event: any) => {
        event.preventDefault();
        submitAddress();
    }

    const handleChangePw = (event: any) => {
        setDataPass({ ...dataPass, [event.target.name]: event.target.value })
    }

    const handleConfirmPassword = (event: any) => {
        if (dataPass.newpassword === event.target.value) {
            setPasswordWarn('success');
        }
        else {
            setPasswordWarn('warning');
        }
    }

    const handleSubmitPass = (event: any) => {
        event.preventDefault();

        if (passwordWarn === 'success') {
            submitChangePass();
        }
        else {
            console.log("pass tidak cocok")
        }
    }

    const submitAddress = async () => {
        await AxiosInstance.post(process.env.REACT_APP_MINUMMINUM_PUBLIC_API + '/customers/address', addAddress).then(
            res => {
                if (res.data.Status === true) {
                    setStatus({ status: true, message: res.data.result.Message });
                    console.log(res.data);
                    setTimeout(
                        () => { childChanged(); setOpenModalAdd(false); setAddAddress(initialAddAddressState); setStatus({ status: false, message: '' }) },
                        2000
                    );
                }
                else {
                    console.log(res.data);
                }
            }
        )
    }

    const submitChangePass = async () => {
        await AxiosInstance.put(process.env.REACT_APP_MINUMMINUM_PUBLIC_API + '/customers/change_password', dataPass).then(
            res => {
                if (res.data.Status === true) {
                    console.log(res.data);
                    window.location.reload();
                }
                else {
                    console.log(res.data);
                }
            }
        )
    }

    const logOut = () => {
        cookies.remove('token');
        cookies.remove('isLoggedIn');
        cookies.remove('fullName');
        window.location.replace('/login');
    }

    useEffect(() => {
        const getUser = async () => {
            try {
                let res = await AxiosInstance.get('/customers/myprofile');

                if (res.status === 200 && res.data.count > 0) {
                    setUser(res.data.result);
                    setDataPass(p => { return ({ ...p, username: res.data.result[0].email }) });
                }
                else {
                    setUser([]);
                }
            }
            catch (error) {
                setUser([]);
                console.log("error user");
            }
            setLoading(l => { return ({ ...l, profile: true }) });
        }

        const getProvince = async () => {
            await axios.get(process.env.REACT_APP_MINUMMINUM_PUBLIC_API + '/state?find=all').then(res => { setProvince(res.data.result) })
        }

        getUser();
        getProvince();

        return () => {
            setLoading(l => { return ({ ...l, profile: false }) });
            setUser([]);
            setProvince([]);
        }
    }, []);

    useEffect(() => {
        const getAddress = async () => {
            await AxiosInstance.get('/customers/address').then(
                res => {
                    if (res.status === 200 && res.data.count > 0) {
                        setAddress(res.data.result);
                    }
                    else {
                        setAddress([]);
                    }
                }
            );
            setLoading(l => { return ({ ...l, address: true }) });
        }

        if (user.length !== 0) {
            getAddress();
        }

        return () => {
            setAddress([]);
            setLoading(l => { return ({ ...l, address: false }) });
        }
    }, [user, changed])

    useEffect(() => {

        // reset selected city
        setCity([]);

        const getCity = async () => {
            await axios.post(process.env.REACT_APP_MINUMMINUM_PUBLIC_API + '/state/city_by_province', {
                "provinceName": addAddress.state_name
            }).then(
                res => {
                    if (res.data.count === 0) {
                        setCity([]);
                    }
                    else {
                        setCity(res.data.result);
                        setAddAddress(p => { return ({ ...p, city: res.data.result[0].cityName + "#" + res.data.result[0].id }) });
                    }
                }
            )
        }

        if (addAddress.state_name !== "") {
            getCity();
        }

        return () => { setCity([]) }
    }, [addAddress.state_name])

    useEffect(() => {

        // reset selected district
        setDistrict([]);

        const getCity = async () => {
            await axios.get(process.env.REACT_APP_MINUMMINUM_PUBLIC_API + '/state/district_by_city/' + addAddress.city_id).then(
                res => {
                    if (res.data.count === 0) {
                        setDistrict([]);
                    }
                    else {
                        setDistrict(res.data.result);
                        // setDataUpdate(p => { return ({ ...p, city: res.data.result[0].cityName + "#" + res.data.result[0].id }) });
                    }
                }
            )
        }

        if (addAddress.city_id !== undefined && addAddress.city_id !== 0) {
            getCity();
        }

        return () => { setDistrict([]) }
    }, [addAddress.city_id]);

    useEffect(() => {

        // reset selected kelurahan
        setKelurahan([]);

        const getKelurahan = async () => {
            await axios.get(process.env.REACT_APP_MINUMMINUM_PUBLIC_API + '/state/kelurahan_by_district/' + addAddress.district_id).then(
                res => {
                    if (res.data.count === 0) {
                        setKelurahan([]);
                    }
                    else {
                        setKelurahan(res.data.result);
                        // setDataUpdate(p => { return ({ ...p, city: res.data.result[0].cityName + "#" + res.data.result[0].id }) });
                    }
                }
            )
        }

        if (addAddress.district_id !== undefined && addAddress.district_id !== 0) {
            getKelurahan();
        }

        return () => { setKelurahan([]) }
    }, [addAddress.district_id]);

    useEffect(() => {
        setAddAddress(d => ({ ...d, destination_coordinate: String(pinpoint.lat + ", " + pinpoint.lng) }))
        return () => { }
    }, [pinpoint])

    return (
        <div>
            {!loading.profile && <Skeleton type="productDetail"></Skeleton>}
            {user.length > 0 && loading.profile ?
                <Grid container spacing={2}>
                    <Grid item xs={12} md={3}>
                        <Card>
                            <AvatarBox>
                                <img src="/assets/images/profile-photo-default.png" alt="" />
                            </AvatarBox>
                            <ActionButton label="Logout" type="secondary-full" onClick={logOut} />
                            <p></p>
                            {/* <ActionButton label="Change Profile Photo" type="secondary-full" onClick={console.log("")}/> */}
                            <p></p>
                            <ActionButton label="Change Password" type="secondary-full" onClick={() => setOpenModalChangePw(!openModalChangePw)}/>
                            <Modal open={openModalChangePw} onClose={() => setOpenModalChangePw(!openModalChangePw)}>
                                <ModalHeading text="Change Password" />
                                <form onSubmit={handleSubmitPass}>
                                    <Grid container spacing={2}>
                                        <input type="hidden" value={dataPass.username} />
                                        <Grid item xs={12}>
                                            <TextInput onInputChange={handleChangePw} type="password" required={true} name="password" label="Old Password" placeholder="Old Password" value={dataPass.password} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextInput onInputChange={handleChangePw} type="password" required={true} name="newpassword" label="New Password" placeholder="New Password" value={dataPass.newpassword} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextInput onInputChange={handleConfirmPassword} type="password" required={true} name="checknewpassword" label="Confirm New Password" placeholder="Confirm New Password" status={passwordWarn} />
                                        </Grid>
                                    </Grid>
                                    <Grid container justifyContent="flex-end" spacing={2}>
                                        <Grid item xs={12} md={6}>
                                            <GroupButtonForm>
                                                <CancelButton onClick={() => setOpenModalChangePw(false)} label="Cancel"></CancelButton>
                                                <ButtonForm label="Submit"></ButtonForm>
                                            </GroupButtonForm>
                                        </Grid>
                                    </Grid>
                                </form>
                            </Modal>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Card>
                                    <ProfileHeading text="Personal Information" />
                                    <Typography variant="subtitle1">
                                        Name
                                    </Typography>
                                    <Typography variant="h6" style={{ fontWeight: 'bold' }} gutterBottom>
                                        {user[0].display_name}
                                    </Typography>
                                    <Typography variant="subtitle1">
                                        Email
                                    </Typography>
                                    <Typography variant="h6" style={{ fontWeight: 'bold' }} gutterBottom>
                                        {user[0].email}
                                    </Typography>
                                    <Typography variant="subtitle1">
                                        Phone
                                    </Typography>
                                    <Typography variant="h6" style={{ fontWeight: 'bold' }} gutterBottom>
                                        {user[0].phone}
                                    </Typography>
                                    <Typography variant="subtitle1">
                                        Mobile
                                    </Typography>
                                    <Typography variant="h6" style={{ fontWeight: 'bold' }} gutterBottom>
                                        {user[0].mobile}
                                    </Typography>
                                </Card>
                            </Grid>
                            <Grid item xs={12}>
                                <Card>
                                    <ProfileHeading text="Address Information" />
                                    <Grid container spacing={3}>
                                        {!loading.address && [1, 2].map((items, index) => (<Grid item key={index} xs={6}><Skeleton type="address" /></Grid>))}
                                        {loading && address.length > 0 && address.map((addressItem) => (
                                            <Grid key={addressItem.id} item xs={12} md={6}>
                                                <AddressBox onClick={(e: any) => e.preventDefault} style={{}}>
                                                    <Typography variant="subtitle1" style={{ fontWeight: 'bold' }} gutterBottom>
                                                        {addressItem.type} Address
                                                    </Typography>
                                                    <Typography variant="subtitle1">
                                                        {addressItem.name}
                                                    </Typography>
                                                    <Typography variant="subtitle2" gutterBottom>
                                                        {addressItem.phone}, {addressItem.mobile}
                                                    </Typography>
                                                    <Typography variant="subtitle2" gutterBottom>
                                                        {addressItem.street}, {addressItem.street2}, {addressItem.urban_village_id[1]}, {addressItem.district_id[1]}, {addressItem.city_id[1]},<br /> {addressItem.state_id[1]}, {addressItem.zip}
                                                    </Typography>
                                                    <PinpointStatus>
                                                        <RoomIcon style={{ color: String(addressItem.destination_coordinate) !== "false" ? "#C98B12" : "#333" }} />
                                                        <Typography variant="subtitle2" style={{ lineHeight: 'inherit' }}>
                                                            {String(addressItem.destination_coordinate) !== "false" ? "Already pinpoint" : "Haven't pinpoint"}
                                                        </Typography>
                                                    </PinpointStatus>
                                                    <ButtonGroup>
                                                        <EditAddress childChanged={childChanged} data={addressItem} province={province} />
                                                        <DeleteAddress childChanged={childChanged} id={addressItem.id} type={addressItem.type} />
                                                    </ButtonGroup>
                                                </AddressBox>
                                            </Grid>
                                        ))}
                                    </Grid>
                                    <ButtonGroup>
                                        <ActionButton label="Add Address" type="secondary" onClick={() => setOpenModalAdd(!openModalAdd)} />
                                    </ButtonGroup>
                                    <Modal open={openModalAdd} onClose={() => setOpenModalAdd(!openModalAdd)}>
                                        <ModalHeading text="Add Address" />
                                        <form onSubmit={handleClick}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} md={6}>
                                                    <SelectOption onInputChange={handleInputChange} required={true} name="addr_type" label="Address Type" data={AddressType} />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <TextInput onInputChange={handleInputChange} required={true} name="contact_name" label="Contact Name" placeholder="Contact Name" />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <TextInput onInputChange={handleInputChange} required={true} name="phone" label="Phone" placeholder="Phone" />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <TextInput onInputChange={handleInputChange} required={true} name="mobile" label="Mobile" placeholder="Mobile" />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <TextInput onInputChange={handleInputChange} required={true} name="street" label="Street Address" placeholder="Street Address" />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <TextInput onInputChange={handleInputChange} required={true} name="street2" label="Street Address 2" placeholder="Street Address 2" />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <SelectOption onInputChange={handleInputProvince} required={true} name="state_id" label="Province" data={province} value={addAddress.state_id} />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <SelectOption onInputChange={handleInputCity} required={true} name="city" label="City" data={city.map(item => { return { name: item.cityName, id: item.id } })} value={addAddress.city_id} />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <SelectOption onInputChange={handleInputDistrict} required={true} name="district" label="District" data={district.map(item => { return { name: item.districtName, id: item.id } })} value={addAddress.district_id} />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <SelectOption onInputChange={handleInputKelurahan} required={true} name="kelurahan" label="Urban Village" data={kelurahan.map(item => { return { name: item.kelurahanName, id: item.id } })} value={addAddress.urban_village_id} />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <TextInput onInputChange={handleInputChange} required={true} name="zip" label="Zip Code" placeholder="Zip code" value={addAddress.zip} />
                                                </Grid>
                                                <Grid item xs={12} md={12}>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        Pinpoint
                                                    </Typography>
                                                    <Maps onClick={setPinpoint}
                                                        name={addAddress.contact_name}
                                                        lat={addAddress.destination_coordinate !== "" ? Number(addAddress.destination_coordinate.split(", ")[0]) : 0}
                                                        lng={addAddress.destination_coordinate !== "" ? Number(addAddress.destination_coordinate.split(", ")[1]) : 0} />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    {status.status && <Notification type="info" text={status.message} />}
                                                </Grid>
                                            </Grid>
                                            <Grid container justifyContent="flex-end" spacing={2}>
                                                <Grid item xs={12} md={6}>
                                                    <GroupButtonForm>
                                                        <CancelButton onClick={() => setOpenModalAdd(false)} label="Cancel"></CancelButton>
                                                        <ButtonForm label="Add Address"></ButtonForm>
                                                    </GroupButtonForm>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    </Modal>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                : ''}
        </div>

    )
}

const UserProfile = () => {
    const classes = useStyles();
    const cookies = new Cookies();

    if (!cookies.get('isLoggedIn')) {
        return <Redirect to="/login" />
    }

    return (
        <div className={classes.root}>
            <Container>
                {cookies.get('isLoggedIn') && cookies.get('token') ? <Profile /> : ''}
            </Container>
        </div>
    )
}

const AvatarBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    margin-bottom: 1rem;

    img { width: 80% }

    ${customMedia.lessThan("desktop")`
        img { width: 150px }
    `}
`

export default UserProfile;