import { useState, useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { Container, Typography, Grid } from '@material-ui/core';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';

import Cookies from 'universal-cookie';
import AxiosInstance from '../utils/axiosInstance';
import NumberFormat from 'react-number-format';

import { DataHeading, ModalHeading, PageHeading } from './heading/Heading';
import { AddressBox, DataBox, ShippingBox, StickyCard } from './container/Card';
import { AgreementInformation, DataList, HighligthDataList } from './container/CartContainer';
import { Notification } from './notification/Notification';
import { ActionButton, ButtonGroup, CartFullButton } from './DynamicButton';
import { Modal } from './container/Modal';
import Skeleton from '../skeleton/Skeleton';

import { AddressState, CourierState, ProductState } from '../state/state';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingBottom: '100px',
        },
        selectedAddress: {
            position: 'absolute',
            top: '10px',
            right: '10px',
            color: '#FCC201',
            textTransform: 'uppercase',
        },
    }),
);

const Checkout = () => {
    const classes = useStyles();
    const cookies = new Cookies();

    const [token] = useState(cookies.get('token'));
    const [open, setOpen] = useState(false);
    const [cart, setCart] = useState({
        id: 0,
        date_order: '',
        amount_total: 0,
        amount_tax: 0,
        amount_untaxed: 0,
        cart_quantity: 0,
        currency_id: []
    });
    const [product, setProduct] = useState<ProductState[]>([]);
    const [address, setAddress] = useState<AddressState[]>([]);
    const [selectedAddr, setSelectedAddr] = useState(0);
    const [checkoutData, setCheckoutData] = useState({
        salesheaderid: 0,
        partnershippingid: 0,
        carrierid: 0,
        deliveryservicetype: '',
        deliveryprice: 0,
    });
    const [totalPrice, setTotalPrice] = useState(0);
    const [listCourier, setListCourier] = useState<CourierState[]>([]);
    const [courier, setCourier] = useState({ salesheaderid: 0, partnershippingid: 0, });
    const [checkoutLoading, setCheckoutLoading] = useState({ loading: false, message: '' });
    const [loading, setLoading] = useState({ product: false, courier: false });

    const handleCheckout = () => {
        if (checkoutData.salesheaderid !== 0 && checkoutData.carrierid !== 0 && totalPrice !== 0 ) {
            checkoutCart();
        }
        else{
            setCheckoutLoading({loading: true, message: "Please fill out all form"});
            setTimeout(
                () => { setCheckoutLoading({loading: false, message: ""}); },
                5000
            );
        }
    }

    const checkoutCart = async () => {
        setCheckoutLoading({ loading: true, message: 'Ordering your cart, please wait...' });
        await AxiosInstance.post('/sales/checkout', checkoutData).then(
            res => {
                if (res.data.Status === true) {
                    setCheckoutLoading({ loading: true, message: 'Your cart has been ordered.' });
                    window.open(res.data.result);
                    setTimeout(
                        () => window.location.replace('/purchase'),
                        2000
                    );
                }
            }
        )
    }

    useEffect(() => {
        const getCart = async () => {
            await AxiosInstance.get('/sales?find=cart').then(
                res => {
                    if (res.status === 200 && res.data.count !== 0) {
                        setCart(res.data.result);
                        setCheckoutData(c => { return ({ ...c, salesheaderid: res.data.result.id }) });
                        setCourier(c => { return ({ ...c, salesheaderid: res.data.result.id }) });
                    }
                    else {
                        setCart(c => { return (c) });
                    }
                }
            )

        }

        const getAddress = async () => {
            await AxiosInstance.get('/customers/address').then(
                res => {
                    if (res.status === 200 && res.data.count > 0) {
                        setAddress(res.data.result);
                        setSelectedAddr(res.data.result[0].id);
                        setCheckoutData(c => { return ({ ...c, partnershippingid: res.data.result[0].id }) });
                        setCourier(c => { return ({ ...c, partnershippingid: res.data.result[0].id }) });
                    }
                    else {
                        setAddress([]);
                    }
                }
            )
        }

        if (token) {
            getCart();
            getAddress();
        }
        else {
            setCart(c => { return (c) });
            setAddress([])
        }

        return () => { }
    }, [token])

    useEffect(() => {
        const getProduct = async () => {
            await AxiosInstance.get('/sales_detail/' + cart.id).then(
                res => {
                    if (res.status === 200 && res.data.count >= 0) {
                        setProduct(res.data.result);
                    }
                    else {
                        setProduct([]);
                    }
                }
            );
            setLoading(l => { return ({ ...l, product: true }) });
        }

        if (cart.id !== 0) {
            getProduct();
        }

        return () => { setLoading(l => { return ({ ...l, product: false }) }) }
    }, [cart])

    useEffect(() => {
        const getCourier = async () => {
            await AxiosInstance.post('/sales/list_carriers', courier).then(
                res => {
                    if (res.data.count > 0) {
                        setListCourier(res.data.result);
                    }
                    else {
                        setListCourier([])
                    }
                }
            )
            setLoading(l => { return ({ ...l, courier: true }) });
        }

        if (token && courier.partnershippingid !== 0) {
            getCourier();
        }

        return () => { setListCourier([]); setLoading(l => { return ({ ...l, courier: false }) }); }
    }, [token, courier])

    return (
        <Container className={classes.root}>
            <PageHeading text="Checkout" />
            <Grid container spacing={3}>
                <Grid item xs={12} md={7}>
                    <DataBox pd="0 0 1rem">
                        <DataHeading>
                            <h6>Select Address</h6>
                        </DataHeading>
                        <Grid container spacing={2}>
                            {!loading.product && [1, 2, 3].map((items, index) => (<Grid item xs={12} key={index}><Skeleton type="address" /></Grid>))}
                            {loading.product && address.length > 0 && address.map((addressItem) => (
                                <Grid key={addressItem.id} item xs={12}>
                                    <AddressBox style={{ outline: selectedAddr === addressItem.id ? '3px solid #FCC201' : '', cursor: 'pointer' }}
                                        onClick={() => {
                                            setSelectedAddr(addressItem.id);
                                            setCheckoutData({ ...checkoutData, partnershippingid: addressItem.id, carrierid: 0, deliveryprice: 0, deliveryservicetype: '' });
                                            setCourier({ partnershippingid: addressItem.id, salesheaderid: cart.id });
                                            setTotalPrice(0);
                                        }}
                                    >
                                        <span className={classes.selectedAddress}>
                                            {selectedAddr === addressItem.id ? <RadioButtonCheckedIcon /> : <RadioButtonUncheckedIcon />}
                                        </span>
                                        <Typography variant="subtitle1" style={{ fontWeight: 'bold' }} gutterBottom>
                                            Address {addressItem.type}
                                        </Typography>
                                        <Typography variant="subtitle1" gutterBottom>
                                            {addressItem.name}, {addressItem.phone}, {addressItem.mobile}
                                        </Typography>
                                        <Typography variant="subtitle1" gutterBottom>
                                            {addressItem.street}, {addressItem.street2}, {addressItem.urban_village_id[1]}, {addressItem.district_id[1]}, {addressItem.city_id[1]}, <br /> {addressItem.state_id[1]}, {addressItem.zip}
                                        </Typography>
                                    </AddressBox>
                                </Grid>
                            ))}
                        </Grid>
                    </DataBox>
                    <DataBox pd="1rem 0">
                        <DataHeading>
                            <h6>Shipping Method</h6>
                        </DataHeading>
                        <Grid container spacing={2}>
                            {!loading.courier && courier.partnershippingid !== 0 && <Grid item xs={12}><Skeleton type="listItem" /></Grid>}
                            {loading && listCourier.length > 0 && listCourier.map((items, index) => (
                                <Grid item xs={12} key={index}>
                                    <ShippingBox active={Number(items.carrier_id) === Number(checkoutData.carrierid) ? true : false} onClick={() => {
                                        setTotalPrice(items.new_amount_delivery + cart.amount_total);
                                        setCheckoutData({ ...checkoutData, deliveryservicetype: items.carrier_name, deliveryprice: items.new_amount_delivery, salesheaderid: cart.id, carrierid: Number(items.carrier_id) });
                                    }}>
                                        <DataList>
                                            <h6>{items.carrier_name}</h6>
                                            <p>IDR <NumberFormat value={items.new_amount_delivery} thousandSeparator="." decimalSeparator="," displayType={'text'} /></p>
                                        </DataList>
                                    </ShippingBox>
                                </Grid>
                            ))}
                        </Grid>
                    </DataBox>
                </Grid>
                <Grid item xs={12} md={5}>
                    {!loading.product && <Skeleton type="cartBox" />}
                    {loading.product &&
                        <StickyCard>
                            <DataList>
                                <h4>Cart Summary ({cart.cart_quantity} {cart.cart_quantity > 1 ? 'items' : 'item'})</h4>
                            </DataList>
                            {product.length > 0 && product.map((items, index) => (
                                <DataList key={index}>
                                    <h5>{items.display_name} {items.product_uom_qty > 1 ? <b>({items.product_uom_qty} items)</b> : ''}</h5>
                                    <p>{items.currency_id[1]} <NumberFormat value={items.price_subtotal} thousandSeparator="." decimalSeparator="," displayType={'text'} /></p>
                                </DataList>
                            ))}
                            <DataList>
                                <h5>Subtotal</h5>
                                <p>{cart.currency_id[1]} <NumberFormat value={cart.amount_untaxed} thousandSeparator="." decimalSeparator="," displayType={'text'} /></p>
                            </DataList>
                            <DataList>
                                <h5>Tax</h5>
                                <p>{cart.currency_id[1]} <NumberFormat value={cart.amount_tax} thousandSeparator="." decimalSeparator="," displayType={'text'} /></p>
                            </DataList>
                            <HighligthDataList>
                                <h5>Subtotal (taxed)</h5>
                                <p>{cart.currency_id[1]} <NumberFormat value={cart.amount_total} thousandSeparator="." decimalSeparator="," displayType={'text'} /></p>
                            </HighligthDataList>
                            <DataList>
                                <h5>Shipping: <br /> <b>{checkoutData.deliveryservicetype}</b></h5>
                                <p>{cart.currency_id[1]} <NumberFormat value={checkoutData.deliveryprice} thousandSeparator="." decimalSeparator="," displayType={'text'} /></p>
                            </DataList>
                            <HighligthDataList>
                                <h5>Total Price</h5>
                                <p>{cart.currency_id[1]} <NumberFormat value={totalPrice} thousandSeparator="." decimalSeparator="," displayType={'text'} /></p>
                            </HighligthDataList>
                            <AgreementInformation>
                                In accordance with the laws of the Republic of Indonesia, by clicking the button below, you acknowledge that you are <b>21 years or older.</b>
                            </AgreementInformation>
                            <DataBox pd="0.5rem 0">
                                {checkoutLoading.loading && <Notification type="info" text={checkoutLoading.message} />}
                            </DataBox>
                            <CartFullButton onClick={handleCheckout}>Checkout Cart</CartFullButton>
                            <ButtonGroup>
                                <ActionButton type="secondary-small" label="Refund Policy" onClick={() => setOpen(!open)} />
                                <ActionButton type="secondary-small" label="Shipping Policy" onClick={() => setOpen(!open)} />
                                <Modal open={open} onClose={() => setOpen(!open)}>
                                    <ModalHeading text="Policy" />
                                    <DataBox pd="0.5rem 0">
                                        <Typography variant="body1">
                                            (Khusus untuk order area Jakarta dengan INSTAN / SAMEDAY) Apabila Anda ingin mengajukan tukar barang untuk item yang pecah, bocor atau hilang, harap memberitahu kami terlebih dahulu melalui chat. Pengajuan tukar barang hanya akan kami proses apabila disertakan dengan video dan foto unboxing tepat saat sebelum paket dibuka.
                                        </Typography>
                                    </DataBox>
                                    <DataBox pd="0.5rem 0">
                                        <Typography variant="body1">
                                            Harap dicatat, untuk pengiriman dengan FREE ONGKIR atau opsi Regular, kami tidak dapat menjamin keamanan paket karena semua pengiriman diproses oleh kurir pihak ketiga dan asuransi tidak berlaku bagi barang alkohol. Dengan demikian, kami tidak dapat bertanggung jawab apabila terdapat kendala dalam pengiriman/saat paket diterima (botol pecah, bocor atau hilang). Kami akan mengevaluasi kasus per kasus dan membuat keputusan.
                                        </Typography>
                                    </DataBox>
                                    <DataBox pd="0.5rem 0">
                                        <Typography variant="body1">
                                            Harap mengajukan komplain apabila barang tidak kunjung diterima atau terdapat item yang kurang. Kami akan membantu proses penyelesaian dengan claim asuransi atau refund.
                                        </Typography>
                                    </DataBox>
                                    <DataBox pd="0.5rem 0">
                                        <Typography variant="body1">
                                            Apabila Anda menginginkan gift / promo dari barang yang akan dibeli, harap menanyakan ketersediaan gift / promo dengan admin kami terlebih dahulu melalui chat. Setiap promo di toko kami berlaku WHILE STOCK LASTS atau selama persediaan masih ada saja, biasanya bisa diperhatikan dari gambar utama produk saat check out. Apabila gambar hanya memuat gambar botol, tanpa hadiah, kemungkinan besar gift promonya sudah habis dan akan kami proses pengiriman botolnya saja sesuai pesanan. Harap chat / tulis note saat order apabila bonus produk penting bagi Anda.
                                        </Typography>
                                    </DataBox>
                                    <DataBox pd="0.5rem 0">
                                        <Typography variant="body1">
                                            Membeli di toko kami berarti setuju dengan segala kebijakan yang sudah ditetapkan. Terima kasih atas pengertian Anda.
                                        </Typography>
                                    </DataBox>
                                </Modal>
                            </ButtonGroup>
                        </StickyCard>
                    }
                </Grid>
            </Grid>
        </Container>
    );
}

export default Checkout;