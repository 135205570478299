import { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useHistory } from "react-router-dom";
import Cookies from 'universal-cookie';
import { Grid } from '@material-ui/core';

import TextInput from '../../components/form/TextInput';
import { ButtonForm } from '../../components/form/ButtonForm';
import { AuthContainer, ButtonContainer, SmallFormContainer, HeadingImage } from '../../components/container/AuthContainer';
import { Notification } from '../../components/notification/Notification';

const Login = () => {
    const history = useHistory();
    const cookies = new Cookies();

    const [authData, setAuthData] = useState({
        username: '',
        password: ''
    });
    const [loading, setLoading] = useState(true);
    const [status, setStatus] = useState(false);
    const [message, setMessage] = useState('');

    let url = process.env.REACT_APP_MINUMMINUM_PUBLIC_API + '/customers/login';

    async function login() {
        setLoading(false);

        try {
            await axios.post(url, authData).then(
                res => {
                    if (res.data.Status === true) {
                        cookies.set('token', res.data.result.access_token, { path: '/' });
                        cookies.set('fullName', res.data.result.full_name, { path: '/' });
                        cookies.set('isLoggedIn', true, { path: '/' });
                        setStatus(res.data.Status);
                        setMessage(res.data.Message);
                    }
                    else {
                        setStatus(res.data.Status);
                        setMessage(res.data.Message);
                    }
                }
            )

        } catch (error) {
            setStatus(false);
            console.log(error)
        }

        setLoading(true);
    }
    
    const handleInputChange = (event: any) => {
        setAuthData({ ...authData, [event.target.name]: event.target.value })
    }

    useEffect(() => {
        if (loading && status === true) {
            setTimeout(
                () => window.location.replace('/'),
                1000
            );
        }
        return () => { }
    }, [loading, status, history])

    return (
        <AuthContainer>
            <SmallFormContainer>
                <HeadingImage text="Read our terms and service" />
                <form onSubmit={(e) => { e.preventDefault(); login(); }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            {!loading && <Notification type="loading" text="Trying to register..." />}
                            {loading && message && <Notification type="info" text={message} />}
                        </Grid>
                        <Grid item xs={12}>
                            <TextInput onInputChange={handleInputChange} value={authData.username} name="username" label="Email" placeholder="Email Address" />
                        </Grid>
                        <Grid item xs={12}>
                            <TextInput onInputChange={handleInputChange} value={authData.password} name="password" type="password" label="Password" placeholder="Password" />
                        </Grid>
                        <Grid item xs={12}>
                            <ButtonContainer>
                                <ButtonForm onClick={() => login()} label="Login"></ButtonForm>
                            </ButtonContainer>
                        </Grid>
                        <Grid item xs={12}>
                            <Link className="auth-link" to="/">Skip, i wanna explore first</Link>
                        </Grid>
                        <Grid item xs={12}>
                            <Link className="auth-link" to="/register">Dont have account? Register here</Link>
                        </Grid>
                    </Grid>
                </form>
            </SmallFormContainer>
        </AuthContainer>
    );
}

export default Login;