import { FC, ReactNode } from 'react';
import styled from 'styled-components';
import media from "styled-media-query";

export const ButtonGroup: FC<{}> = ({ children }) => {
    return (
        <Group>
            {children}
        </Group>
    )
}

export const ActionButton = (props: { label: string, onClick: any, type: string }) => {
    return (
        <>
            {props.type === "primary" ? <ActButton onClick={props.onClick}>{props.label}</ActButton>
                : props.type === "secondary" ? <SecondaryActButton onClick={props.onClick}>{props.label}</SecondaryActButton>
                    : props.type === "secondary-full" ? <SecondFull onClick={props.onClick}>{props.label}</SecondFull>
                        : props.type === "secondary-small" ? <SecondSmall onClick={props.onClick}>{props.label}</SecondSmall>
                            : props.type === "cart" ? <CartSmallButton onClick={props.onClick}>{props.label}</CartSmallButton>
                                : props.type === "cart-full" ? <CartFullButton onClick={props.onClick}>{props.label}</CartFullButton>
                                    : <SecondaryActButton onClick={props.onClick}>{props.label}</SecondaryActButton>}
        </>

    )
}

export const IconButton = (props: { children: ReactNode }) => {
    return (
        <IconActButton>
            {props.children}
        </IconActButton>
    )
}

export const CountButton = (props: { children: ReactNode, onClick: any, disabled: boolean }) => {
    return (
        <Count aria-label="Paging Button" disabled={props.disabled} onClick={props.onClick}>
            {props.children}
        </Count>
    )
}

export const EcomIcon = (props: { url: string, type: string }) => {
    if (props.type === "blibli") {
        return <BlibliIcon title="Blibli" href={props.url} target="_blank" rel="noopener noreferrer" />
    } else if (props.type === "tokped") {
        return <TokpedIcon title="Tokopedia" href={props.url} target="_blank" rel="noopener noreferrer" />
    } else if (props.type === "gofood") {
        return <GofoodIcon title="Gofood" href={props.url} target="_blank" rel="noopener noreferrer" />
    } else if (props.type === "grabfood_jkt") {
        return <GrabfoodIcon title="Grabfood Jakarta" href={props.url} target="_blank" rel="noopener noreferrer" />
    } else if (props.type === "grabfood_mnd") {
        return <GrabfoodIcon title="Grabfood Manado" href={props.url} target="_blank" rel="noopener noreferrer" />
    }
    else {
        return <BlibliIcon href={props.url} target="_blank" rel="noopener noreferrer" />
    }
}

const Group = styled.div`
    margin-top: 15px;
    display: flex;
    justify-content: end;
    gap: 15px;
    flex-wrap: wrap;
`

export const WrapGroup = styled(Group)`
    flex-wrap: nowrap;
`

export const LeftButtonGroup = styled(Group)`
    margin-top: 0;
    justify-content: flex-start;
`

export const ActButton = styled.button`
    width: auto;
    cursor: pointer;
    border: 0;
    background: linear-gradient(to right, #B78628, #FCC201, #FCC201, #B78628);
    padding: 12px 35px;
    border-radius: 7px;
    font-size: 0.875rem;
    color: #333;
    font-family: 'Arial', Courier, monospace;
    font-weight: bold;
    transition: all 0.3s ease-in-out;
    white-space: nowrap;

    ${media.lessThan("medium")`
        padding: 10px 20px;
    `}

    ${media.lessThan("small")`
        padding: 8px 15px; 
    `}

    :hover {
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    }
`

export const CartSmallButton = styled(ActButton)`
    font-size: 0.875rem;
`

export const CartFullButton = styled(ActButton)`
    width: 100%;
`

const SecondaryActButton = styled(ActButton)`
    background: transparent;
    border: 1px solid #d8d8d8;
    font-size: 0.875rem;

    :hover {
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
    }
`

const SecondSmall = styled(SecondaryActButton)`
    font-size: 0.875rem;
    padding: 10px 20px;
`

const SecondFull = styled(SecondaryActButton)`
    width: 100%;
`

const IconActButton = styled(SecondaryActButton)`
    box-shadow: none;
    color: #C2994B;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    
    svg {
        font-size: 2rem;
    }

    span {
        font-size: 1.25rem;
    }

    :hover {
        box-shadow: none;
    }
`

const Count = styled.button`
    background: transparent;
    width: 30px;
    height: 30px;
    box-shadow: none;
    border: 1px solid #b8b8b8;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease-in-out;
    font-family: 'Arial', Courier, monospace;

    :hover {
        background: rgba(0, 0, 0, 0.05);
    }

    :disabled {
        cursor: auto;
        opacity: 0.5;
        
        :hover {
            background: transparent;
        }
    }
`

export const PagingNumber = styled(Count)`
    border: none;
`

const BlibliIcon = styled.a`
    background: url("/assets/images/blibli-icon.png") center center;
    background-size: cover;
    min-width: 40px;
    height: 40px;
    box-shadow: none;
    border: none;
    border-radius: 5px;
    display: block;
    cursor: pointer;
`

const TokpedIcon = styled(BlibliIcon)`
    background: url("/assets/images/tokped-icon.png") center center;
    background-size: cover;
`

const GofoodIcon = styled(TokpedIcon)`
    background: url("/assets/images/gofood-icon.png") center center no-repeat;
    width: 80px;
    background-size: contain;
`

const GrabfoodIcon = styled(BlibliIcon)`
    background: url("/assets/images/grabfood-icon.png") center center;
    background-size: cover;
`