import React, { useEffect, useState, useContext } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { Typography, Grid, Snackbar, IconButton } from '@material-ui/core';
import FavoriteIcon from '@material-ui/icons/Favorite';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import CloseIcon from '@material-ui/icons/Close';

import { Link, useParams } from 'react-router-dom';
import Cookies from 'universal-cookie';
import axios from 'axios';

import Skeleton from '../skeleton/Skeleton';
import AxiosInstance from '../utils/axiosInstance';
import { BadgeContext } from '../state/context';
import { CardProductBox } from './container/Card';
import { CategoryProduct, ContentProduct, FavContainer, OptionContainer, ProductCartPop, ProductImageContainer } from './container/ProductContainer';
import { Filter } from './form/FormInput';
import { ModalHeading, PageHeading } from './heading/Heading';
import { ActionButton, ButtonGroup, CountButton, PagingNumber } from './DynamicButton';

import { CategoryState, ProductState, RouteState } from '../state/state';
import { ModalNoOverflow } from './container/Modal';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingTop: '0px',
            paddingBottom: '30px',
            minHeight: '50vh'
        },
    }),
);

const ProductGrid = ({ items }: any) => {
    const cookies = new Cookies();
    const context = useContext(BadgeContext);
    const [size, setSize] = useState({ width: 0, height: 0 })
    const [screenSize, getDimension] = useState(window.innerWidth);
    const [addedcart, setAddedcart] = useState({ open: false, message: "" });
    const [snackbar, setSnackbar] = useState({ open: false, message: "" });
    var img = new Image();

    if (screenSize < 600) {
        img.src = items.image_128;
    }
    else {
        img.src = items.image_256;
    }

    async function addWishlist() {
        let res = await AxiosInstance.post('/wishlist', {
            "id": 0,
            "productid": items.id,
        });

        if (res.status === 200 && res.data.Status === true) {
            console.log(res.data.Message);

            let updateBadge = await AxiosInstance.get('/customers/myprofile');

            if (updateBadge.status === 200 && updateBadge.data.count > 0) {
                context.dispatch({
                    type: 'GENERATE',
                    payload: {
                        wishlist_qty: updateBadge.data.result[0].qty_wishlist,
                        cart_qty: updateBadge.data.result[0].qty_cart,
                    }
                });
                setSnackbar({ open: true, message: items.display_name + " has been successfully added to your wishlist." });
            }
        }
        else {
            setSnackbar({ open: true, message: items.display_name + " is already on your wishlist." });
        }
    }

    async function addCart() {
        let res = await AxiosInstance.post('sales_detail/add_cart', {
            "productid": items.id,
            "price": items.promo_price !== 0 ? items.promo_price : items.list_price,
            "quantity": 1
        });

        if (res.status === 200 && res.data.Status === true) {
            console.log(res.data.Message);

            let updateBadge = await AxiosInstance.get('/customers/myprofile');

            if (updateBadge.status === 200 && updateBadge.data.count > 0) {
                context.dispatch({
                    type: 'GENERATE',
                    payload: {
                        wishlist_qty: updateBadge.data.result[0].qty_wishlist,
                        cart_qty: updateBadge.data.result[0].qty_cart,
                    }
                });
                setAddedcart({ open: true, message: items.display_name + " has been successfully added to your cart." });
            }

        }
        else {
            console.log(res.data);
        }
    }

    const handleAddCart = () => {
        if (cookies.get('token')) {
            addCart();
        }
        else {
            setSnackbar({ open: true, message: "You must logged in to add cart." });
        }
    }

    const handleAddWishlist = () => {
        if (cookies.get('token')) {
            addWishlist();
        }
        else {
            setSnackbar({ open: true, message: "You must logged in to add wishlist." });
        }
    }

    const setDimension = () => {
        getDimension(window.innerWidth)
    }

    useEffect(() => {
        window.addEventListener('resize', setDimension);

        return () => {
            window.removeEventListener('resize', setDimension);
        }
    }, [screenSize])

    useEffect(() => {
        img.onload = function () {
            setSize({ width: img.width, height: img.height });
        }
        return () => { setSize({ width: 0, height: 0 }) }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [img.src])


    return (
        <Grid item xs={6} md={3} key={items.id}>
            <ModalNoOverflow open={addedcart.open} onClose={() => setAddedcart({ open: false, message: "" })}>
                <ModalHeading text="Successfully added" />
                <ProductCartPop
                    display_name={items.display_name}
                    currency={items.currency_id[1]}
                    price={items.promo_price !== 0 ? items.promo_price : items.list_price}
                    image={items.image_128}
                    url={`/product/${items.categ_id[1].split(" / ")[1] !== undefined ? items.categ_id[1].split(" / ")[1].toLowerCase() : items.categ_id[1].toLowerCase()}/${items.id}`}
                >
                </ProductCartPop>
            </ModalNoOverflow>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                open={snackbar.open}
                autoHideDuration={5000}
                onClose={() => setSnackbar({ open: false, message: "" })}
                message={snackbar.message}
                action={
                    <>
                        <IconButton aria-label="close snackbar" disableRipple onClick={() => setSnackbar({ open: false, message: "" })}>
                            <CloseIcon fontSize="small" style={{ color: '#FFF' }}/>
                        </IconButton>
                    </>
                }
            />
            <CardProductBox>
                <FavContainer>
                    <FavoriteIcon onClick={handleAddWishlist} />
                </FavContainer>
                <Link to={`/product/${items.categ_id[1].split(" / ")[1] !== undefined ? items.categ_id[1].split(" / ")[1].toLowerCase() : items.categ_id[1].toLowerCase()}/${items.id}`}>
                    <ProductImageContainer>
                        <img width={size.width} height={size.height} src={img.src} alt={items.display_name} />
                    </ProductImageContainer>
                </Link>
                <ContentProduct
                    promo={String(items.promo_description)}
                    display_name={items.display_name}
                    price={items.list_price}
                    promo_price={items.promo_price}
                    currency={items.currency_id[1]}
                    url={`/product/${items.categ_id[1].split(" / ")[1] !== undefined ? items.categ_id[1].split(" / ")[1].toLowerCase() : items.categ_id[1].toLowerCase()}/${items.id}`}
                >
                    <ActionButton type="cart" label="Add to Cart" onClick={handleAddCart} />
                </ContentProduct>
            </CardProductBox>
        </Grid>
    );
}

const ProductList = (props: any) => {
    const params = useParams<RouteState>();

    const [product, setProduct] = useState<ProductState[]>([]);
    const [loading, setLoading] = useState(false);
    const [paging, setPaging] = useState({ current: 1, totalPage: 0 });

    let productAPI = process.env.REACT_APP_MINUMMINUM_PUBLIC_API + '/products?find=all';

    if (params.category == null) {
        if (props.type === 'bestseller') {
            productAPI = process.env.REACT_APP_MINUMMINUM_PUBLIC_API + '/products?find=best_seller&limit=8&offset=1';
        }
        else if (props.type === 'merchandise') {
            productAPI = process.env.REACT_APP_MINUMMINUM_PUBLIC_API + '/products?find=merchandise&limit=4&offset=1';
        }
        else {
            productAPI = process.env.REACT_APP_MINUMMINUM_PUBLIC_API + '/products?find=all&limit=12&offset=' + paging.current;
        }
    }
    else {
        if (params.id == null) {
            productAPI = process.env.REACT_APP_MINUMMINUM_PUBLIC_API + '/products?find=' + params.category + "&limit=12&offset=" + paging.current;
        }
        else {
            productAPI = process.env.REACT_APP_MINUMMINUM_PUBLIC_API + '/products?find=' + params.category + "&categ_id=" + params.id + "&limit=12&offset=" + paging.current;
        }
    }

    if (props.type === 'search') {
        productAPI = process.env.REACT_APP_MINUMMINUM_PUBLIC_API + '/products/search';
    }

    if (props.sort !== '') {
        productAPI = productAPI + '&order=' + props.sort;
    }

    const pages = [];

    if (paging.current > 5 && paging.totalPage > 9) {
        for (let index = paging.current - 2; index <= paging.current + 2; index++) {
            pages.push(index)
        }
    }
    else if (paging.current < 5 && paging.totalPage >= 7) {
        for (let index = 1; index <= 7; index++) {
            pages.push(index)
        }
    }
    else if (paging.totalPage < 7) {
        for (let index = 1; index <= paging.totalPage; index++) {
            pages.push(index)
        }
    }
    else if (paging.current === paging.totalPage && paging.totalPage > 9) {
        for (let index = paging.totalPage - 4; index <= paging.totalPage; index++) {
            pages.push(index)
        }
    }
    else {
        for (let index = 1; index <= paging.totalPage; index++) {
            pages.push(index)
        }
    }

    const ChangePage = (count: number) => {
        setPaging(p => { return ({ ...p, current: p.current + count }) })
    }

    useEffect(() => {
        const getProduct = async () => {
            try {
                if (props.type === 'search') {
                    await axios.post(productAPI, {
                        "find": params.id,
                    }).then(res => {
                        if (res.data.count !== 0) {
                            setProduct(res.data.result);
                        }
                        else {
                            setProduct([]);
                        }
                    })
                }
                else {
                    await axios.get(productAPI).then(res => {
                        if (res.data.count !== 0) {
                            setProduct(res.data.result);
                            setPaging(p => { return ({ ...p, totalPage: res.data.total_pages }) })
                        }
                        else {
                            setProduct([]);
                        }
                    })
                }
            }
            catch (error) {
                setProduct([]);
                console.log("error product");
            }
            setLoading(true);
        }
        getProduct();
        return () => {
            setLoading(false);
            setProduct([]);
        }
    }, [productAPI, params.id, props.type]);

    return (
        <div>
            <Grid container spacing={2}>
                {!loading && [1, 2, 3, 4].map((n) => <Grid item xs={6} md={3} key={n}><Skeleton type="product" /></Grid>)}
                {loading && product.length === 0 ?
                    <Grid item xs={12}><Typography variant="subtitle1">There is no available item in this parameter</Typography></Grid>
                    : product.length > 0 && product.map((items) => (
                        <ProductGrid items={items} key={items.id} ></ProductGrid>
                    ))}
                {props.type !== 'bestseller' && props.type !== 'merchandise' ?
                    <Grid item xs={12}>
                        {product.length > 0 &&
                            <ButtonGroup>
                                <CountButton disabled={paging.current === 1 ? true : false} onClick={() => ChangePage(-1)}><KeyboardArrowLeft /></CountButton>
                                {paging.current > 5 ? <><PagingNumber onClick={() => setPaging(p => { return ({ ...p, current: 1 }) })}>1</PagingNumber><PagingNumber disabled>...</PagingNumber></> : ""}
                                {pages.map((items, index) => (<PagingNumber key={index} onClick={() => setPaging(p => { return ({ ...p, current: items }) })} style={{ fontWeight: items === paging.current ? 'bold' : 'normal', color: items === paging.current ? '#C2994B' : '' }}>{items}</PagingNumber>))}
                                {paging.totalPage > 9 ? <><PagingNumber disabled>...</PagingNumber><PagingNumber onClick={() => setPaging(p => { return ({ ...p, current: 10 }) })}>10</PagingNumber></> : ""}
                                <CountButton disabled={paging.current === paging.totalPage ? true : false} onClick={() => ChangePage(1)}><KeyboardArrowRight /></CountButton>
                            </ButtonGroup>
                        }
                    </Grid>
                    : ''}
            </Grid>
        </div>
    );
}

const GetChildID = (props: any) => {
    const params = useParams<RouteState>();
    const [subCateg, setSubCateg] = useState<CategoryState[]>([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const getCateg = async () => {
            try {
                await axios.get(process.env.REACT_APP_MINUMMINUM_PUBLIC_API + '/ecommerce_category?id=' + props.id).then(res => {
                    setSubCateg(res.data.result);
                })
            } catch (error) {
                console.log(error);
            }
            setLoading(true);
        }

        getCateg();
        return () => {
            setSubCateg([]);
            setLoading(false);
        }
    }, [props.id])

    return (
        <>
            {!loading && <Skeleton type="productCategory" />}
            {loading &&
                <CategoryProduct>
                    <Link className={params.id === props.id.toString() ? "active" : ""} to={`/collection/${props.name.toLowerCase()}/${props.id}`}>
                        All
                    </Link>
                    {subCateg.length > 0 && subCateg.map((items, index) => (
                        <Link key={index} className={params.id === items.id.toString() ? "active" : ""} to={`/collection/${props.name.toLowerCase()}/${items.id}`}>
                            {items.name}
                        </Link>
                    ))}
                </CategoryProduct>
            }
        </>
    )
}

export default function ProductsList(props: any) {
    const classes = useStyles();
    const params = useParams<RouteState>();
    const option = [
        {
            label: 'Latest Product',
            value: 'id%20desc',
        },
        {
            label: 'Highest Price',
            value: 'list_price%20desc',
        },
        {
            label: 'Lowest Price',
            value: 'list_price%20asc',
        }
    ]

    const [sort, setSort] = useState('');
    const [subCateg, setSubCateg] = useState<CategoryState[]>([]);
    const [loading, setLoading] = useState(false);

    let urlCateg = process.env.REACT_APP_MINUMMINUM_PUBLIC_API + '/ecommerce_category';

    if (params.id) {
        urlCateg = process.env.REACT_APP_MINUMMINUM_PUBLIC_API + '/ecommerce_category/' + params.id;
    }

    useEffect(() => {
        const getCateg = async () => {
            try {
                await axios.get(urlCateg).then(res => {
                    setSubCateg(res.data.result);
                })
            } catch (error) {
                console.log(error);
            }
            setLoading(true);
        }

        getCateg();

        return () => {
            setSubCateg([]);
            setLoading(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.category])

    return (
        <div className={classes.root}>

            {props.type !== 'bestseller' && props.type !== 'merchandise' && props.type !== 'search' &&
                <>
                    <PageHeading text={params.category && params.category !== 'all' ? params.category : "All Products"} />
                    <OptionContainer>
                        {!loading && <Skeleton type="productCategory" />}

                        {/* All Product - For Parent Category */}
                        {!params.id &&
                            <CategoryProduct>
                                {subCateg.length > 0 && subCateg.map((items, index) => (
                                    <Link key={index} to={`/collection/${items.display_name.toLowerCase()}/${items.id}`}>
                                        {items.display_name}
                                    </Link>
                                ))}
                            </CategoryProduct>
                        }

                        {/* For Child Category */}
                        {loading && params.id ? subCateg.length > 0 && subCateg.map((items, index) => (
                            <React.Fragment key={index}>
                                {items.parent_id === false ?
                                    <GetChildID id={items.id} name={items.display_name} />
                                    :
                                    <GetChildID id={items.parent_id[0]} name={items.parent_id[1]} />}
                            </React.Fragment>
                        )) : ''}

                        <Filter onInputChange={(e: any) => setSort(e.target.value)} data={option.map(item => { return { name: item.label, id: item.value } })} />
                    </OptionContainer>
                </>
            }

            {props.type === 'search' &&
                <>
                    <PageHeading text="Search" />
                    <Typography variant="body1" gutterBottom>
                        Displaying item for keyword: {params.id}
                    </Typography>
                </>
            }

            <ProductList type={props.type} sort={sort} />
        </div>
    );
} 