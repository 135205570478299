import { useReducer } from 'react';
import { createTheme, responsiveFontSizes, createStyles, makeStyles, Theme, ThemeProvider } from '@material-ui/core/styles';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import './assets/css/global.css';

import Navigation from './components/navbar/Navigation';
import Footer from './components/footer/Footer';
import Login from './page/auth/Login';
import PageContent from './components/PageContent';
import UserProfile from './components/UserProfile';
import Register from './page/auth/Register';

import { AuthContext, AuthReducer, InitialState, BadgeContext, BadgeReducer, InitialBadgeState } from './state/context';

import Home from './page/Home';
import SearchPage from './page/collection/Search';
import CollectionPage from './page/collection/Collection';
import ProductDetailPage from './page/collection/ProductDetailPage';
import PurchasePage from './page/Purchase';
import FavoritePage from './page/Favorite';
import CartPage from './page/Cart';
import CheckoutPage from './page/Checkout';
import StoryPage from './page/StoryPage';

let theme = createTheme({
    typography: {
        fontFamily: [
            'Arial',
        ].join(','),
    }
});

theme = responsiveFontSizes(theme);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            background: 'linear-gradient(to bottom, rgb(252, 194, 1, 0.25) 0, transparent 500px)',
        },
    }),
);

function ScrollToTopOnMount() {
    window.scrollTo(0, 0);
    return null;
}

const LoginPage = () => (
    <Route exact path="/login">
        <ScrollToTopOnMount />
        <Login />
    </Route>
)

const RegisterPage = () => (
    <Route exact path="/register">
        <ScrollToTopOnMount />
        <Register />
    </Route>
)

const Default = () => {
    const [state, dispatch] = useReducer(BadgeReducer, InitialBadgeState);
    return (
        <div>
            <BadgeContext.Provider value={{ state: state, dispatch: dispatch }}>
                <Navigation></Navigation>
                <Route exact path="/">
                    <Home></Home>
                </Route>
                <Route exact path="/search/:id">
                    <ScrollToTopOnMount />
                    <SearchPage></SearchPage>
                </Route>
                <Route exact path="/collection">
                    <ScrollToTopOnMount />
                    <CollectionPage></CollectionPage>
                </Route>
                <Route exact path="/collection/:category/:id">
                    <ScrollToTopOnMount />
                    <CollectionPage></CollectionPage>
                </Route>
                <Route exact path="/product/:category/:id">
                    <ScrollToTopOnMount />
                    <ProductDetailPage />
                </Route>
                <Route path="/favorite">
                    <FavoritePage />
                </Route>
                <Route path="/cart">
                    <CartPage />
                </Route>
                <Route exact path="/purchase">
                    <Redirect to="/purchase/unpaid" />
                </Route>
                <Route exact path="/purchase/:id">
                    <PurchasePage />
                </Route>
                <Route path="/checkout">
                    <CheckoutPage />
                </Route>
                <Route exact path="/story/">
                    <ScrollToTopOnMount />
                    <StoryPage />
                </Route>
                <Route exact path="/story/:category/:id">
                    <ScrollToTopOnMount />
                    <StoryPage />
                </Route>
                <Route exact path="/story/:category">
                    <ScrollToTopOnMount />
                    <StoryPage />
                </Route>
                <Route exact path="/pages/:id">
                    <ScrollToTopOnMount />
                    <PageContent />
                </Route>
                <Route exact path="/profile">
                    <ScrollToTopOnMount />
                    <UserProfile />
                </Route>
                <Footer />
            </BadgeContext.Provider>
        </div>
    )
}

function App() {
    const classes = useStyles();
    const [state, dispatch] = useReducer(AuthReducer, InitialState);

    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <AuthContext.Provider value={{ state: state, dispatch: dispatch }}>
                    <div className={classes.root}>
                        <Switch>
                            <Route exact path="/login" component={LoginPage} />
                            <Route exact path="/register" component={RegisterPage} />
                            <Route component={Default} />
                        </Switch>
                    </div>
                </AuthContext.Provider>
            </BrowserRouter>
        </ThemeProvider>
    );
}

export default App;